import React,{ useState, createContext } from 'react'


export const AuthContext = createContext();

function AuthContextApi(props) {

    const [ auth, setAuth ] = useState(false);
    const [ userDetails, setUserDetails ] = useState([]);
    const [ btcInfo, setBtcInfo ] = useState();
    const [ ethInfo, setEthInfo ] = useState();
    const [ xrpInfo, setXrpInfo ] = useState();
    const [ algoInfo, setAlgoInfo ] = useState();
    const [ solInfo, setSolInfo ] = useState();
    const [ files, setFiles ] = useState([]);
    const [ folders, setFolders ] = useState([])

    const allValues = {
        auth, setAuth, 
        btcInfo, setBtcInfo, 
        ethInfo, setEthInfo, 
        xrpInfo, setXrpInfo, 
        algoInfo, setAlgoInfo, 
        solInfo, setSolInfo, 
        userDetails, setUserDetails
    };
     
    
    return (
        <AuthContext.Provider value={allValues} >
            {props.children}
        </AuthContext.Provider>
    )
}

export default AuthContextApi
