import React, { useEffect, useState, useContext } from "react";
import DashNavbar from "../../../components/DashNavbar";
import styles from "./index.module.css";
import Footer from "../../../components/DashFooter";
import { Helmet } from "react-helmet";
import { useHistory, Link } from "react-router-dom";
import { AuthContext } from "../../../contexts/authContextApi";
import Modal from "react-modal";
import { FaTimes } from "react-icons/fa";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { formatCurrency } from "../../../utils/formatCurrency";
import { Bar } from "react-chartjs-2";
import axios from "../../../utils/axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BsGear } from "react-icons/bs";
import { DataGrid } from "@material-ui/data-grid";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import { MdRefresh } from "react-icons/md";

const tabs = ["Home", "Portfolio", "Explore", "Transactions"];

function Trade() {
  const history = useHistory();
  const { userDetails, btcInfo } = useContext(AuthContext);
  const [orders, setOrders] = useState([]);
  const [btc, setBtc] = useState(0);
  const [usd, setUsd] = useState(btcInfo?.buy);
  const [total, setTotal] = useState(0);
  const [tradeType, setTradeType] = useState([0, "buy"]);
  const [creatingTrade, setCreatingTrade] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [activeTab, setActiveTab] = useState(tabs[0]);
  let token = localStorage.getItem("krkn_pro");
  const rand = () => Math.round(Math.random() * 20 - 10);
  const time = (val) => {
    const today = new Date(val);
    return today.toLocaleString();
  };

  const useStyles = makeStyles({
    root: {
      "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
        outline: "none",
      },
      "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
        {
          display: "none",
        },
    },
  });

  const classes = useStyles();

  const [data] = useState({
    labels: [
      time(Date.now() - 16 * 1000 * 60 * 60),
      time(Date.now() - 14 * 1000 * 60 * 60),
      time(Date.now() - 12 * 1000 * 60 * 60),
      time(Date.now() - 8 * 1000 * 60 * 60),
      time(Date.now() - 6 * 1000 * 60 * 60),
      time(Date.now() - 4 * 1000 * 60 * 60),
      time(Date.now() - 2 * 1000 * 60 * 60),
    ],
    datasets: [
      {
        type: "line",
        label: "Market REM.",
        borderColor: "rgb(54, 162, 235)",
        borderWidth: 1,
        fill: false,
        data: [rand(), rand(), rand(), rand(), rand(), rand(), rand()],
      },
      {
        type: "bar",
        label: "Gen. Pairs",
        backgroundColor: "rgb(255, 99, 132)",
        data: [rand(), rand(), rand(), rand(), rand(), rand(), rand()],
        // borderColor: 'white',
        // borderWidth: 2,
      },
      {
        type: "bar",
        label: "Gen. Pairs2",
        backgroundColor: "rgb(255, 99, 132)",
        data: [rand(), rand(), rand(), rand(), rand(), rand(), rand()],
        // borderColor: 'white',
        // borderWidth: 2,
      },
      {
        type: "bar",
        label: "Volume REM.",
        backgroundColor: "lightgreen",
        data: [rand(), rand(), rand(), rand(), rand(), rand(), rand()],
      },
      {
        type: "bar",
        label: "Volume REM3.",
        backgroundColor: "lightgreen",
        data: [rand(), rand(), rand(), rand(), rand(), rand(), rand()],
      },
      {
        type: "bar",
        label: "Gen. Pairs3",
        backgroundColor: "rgb(255, 99, 132)",
        data: [rand(), rand(), rand(), rand(), rand(), rand(), rand()],
        // borderColor: 'white',
        // borderWidth: 2,
      },
      {
        type: "bar",
        label: "Volume REM2.",
        backgroundColor: "lightgreen",
        data: [rand(), rand(), rand(), rand(), rand(), rand(), rand()],
      },
    ],
  });

  const colors = ["green", "red", "green"];

  const [open, setOpen] = useState(false);
  const [openDisable, setOpenDisable] = useState(false); // to show diable auto trade
  const [autoModal, setAutoModal] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setUsd(btcInfo?.buy);
    setTotal(parseInt(btcInfo?.buy) * btc);
  }, [btcInfo, btc]);

  const customStyles = {
    content: {
      height: "300px",
      width: "400px",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      borderRadius: "10px",
      border: "1px solid rgba(40,36,182, .5)",
      transform: "translate(-50%, -50%)",
    },
  };

  const startAutoTrade = () => {
    axios
      .get("/user/start-auto-trade", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token,
        },
      })
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        toast.error("Something went wrong, try again", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: false,
        });
        if (!err.response.data?.authenticated) {
          history.push("/sign-in");
        }
      });
  };

  const stopAutoTrade = () => {
    axios
      .get("/user/stop-auto-trade", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token,
        },
      })
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        toast.error("Something went wrong, try again", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: false,
        });
        if (!err.response.data?.authenticated) {
          history.push("/sign-in");
        }
      });
  };

  useEffect(() => {
    getOrders();
  }, []);

  const getOrders = () => {
    setRefreshing(true);
    axios
      .get("/user/get-orders", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": token,
        },
      })
      .then((res) => {
        setRefreshing(false);
        setOrders(res.data.orders);
      })
      .catch((err) => {
        setRefreshing(false);
        console.error(err);
      });
  };

  const createOrder = () => {
    if (btc == 0) {
      return toast.warning("Volume cannot be 0 BTC", {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: false,
      });
    }
    if (!usd) {
      return toast.warning("Invalid values, try refreshing the page", {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: false,
      });
    }
    setCreatingTrade(true);
    axios
      .post(
        "/user/create-order",
        {
          type: tradeType[1],
          price: total,
          volume: userDetails.balance / usd,
          rate: usd,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": token,
          },
        }
      )
      .then((res) => {
        setCreatingTrade(false);
        if (res.data.status) {
          getOrders();
        } else {
          toast.warning(res.data.message, {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
          });
        }
      })
      .catch((err) => {
        setCreatingTrade(false);
        toast.error("Somthing went wrong, Please try refreshing the page.", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: false,
        });
        if (!err.response.data?.authenticated) {
          history.push("/sign-in");
        }
        console.error(err);
      });
  };

  const testCron = () => {
    axios.post("/user/do-auto-trade");
  };

  const columns = [
    { field: "opened", headerName: "Opened", width: 152 },
    {
      field: "type",
      headerName: "Type",
      width: 152,
      sortable: false,
      editable: true,
      renderCell: (params) => {
        return (
          <p
            style={{
              fontWeight: "bold",
              color: params.row.type == "buy" ? "orange" : "green",
            }}
          >
            {params.row.type}
          </p>
        );
      },
    },
    {
      field: "pair",
      headerName: "Pair",
      sortable: false,
      width: 152,
      editable: true,
    },
    {
      field: "volume",
      headerName: "Volume",
      sortable: true,
      width: 152,
      renderCell: (params) => {
        return <p>{params.row.volume.toFixed(6)} BTC</p>;
      },
      editable: true,
    },
    {
      field: "cost",
      headerName: "Cost",
      sortable: true,
      renderCell: (params) => {
        return <p>{params.row.cost.toFixed(2)} USD</p>;
      },
      sortable: false,
      width: 152,
      editable: true,
    },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      width: 152,
      editable: true,
    },
  ];

  return (
    <>
      <ToastContainer />
      <Helmet>
        <title>Trade - Cryptify</title>
        <meta name="viewport" content="initial-scale=0" />
      </Helmet>
      <div className={styles.main}>
        <DashNavbar />
        <div className={styles.bodyHeader}>
          <div className={styles.bodyHeader__main}>
            {tabs.map((tab, i) => (
              <p
                style={{
                  color: tab === activeTab ? "black" : "grey",
                  cursor: "pointer",
                }}
                key={i}
              >
                {tab}
              </p>
            ))}
          </div>
        </div>

        {!userDetails?.isLocked && (
          <div className={styles.container}>
            <Bar data={data} height={70} />
          </div>
        )}

        {userDetails.autoTrade ? (
          <div className={styles.container}>
            <p
              style={{ textAlign: "center", color: "grey", fontSize: ".8rem" }}
            >
              <BsGear id="gear1" size={15} className={styles.spin} /> You are
              trading in Auto Mode{" "}
              <span
                onClick={() => setOpenDisable(true)}
                style={{
                  color: "black",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                Stop
              </span>
            </p>
          </div>
        ) : (
          <div className={styles.container}>
            {userDetails?.isLocked && (
              <span className={styles.unlockBar}>
                <span style={{ color: "rgb(217,69,49)", marginLeft: ".85rem" }}>
                  Unlock your account by making a deposit and start trading.{" "}
                  <span
                    onClick={() => history.push("/u/funding")}
                    style={{ color: "blue", cursor: "pointer" }}
                  >
                    Fund Account
                  </span>
                </span>
              </span>
            )}
            <div>
              <span style={{ fontSize: ".8rem", color: "grey" }}>
                Order <br />
                <button
                  onClick={() => setTradeType([0, "buy"])}
                  style={{
                    backgroundColor:
                      tradeType[0] == 0 ? "rgb(36,36,36)" : "white",
                    color: tradeType[0] == 0 ? "white" : "rgb(36,36,36)",
                  }}
                  className={styles.btn_buy}
                >
                  Buy
                </button>
                <button
                  onClick={() => setTradeType([1, "sell"])}
                  style={{
                    backgroundColor:
                      tradeType[0] == 1 ? "rgb(36,36,36)" : "white",
                    color: tradeType[0] == 1 ? "white" : "rgb(36,36,36)",
                  }}
                  className={styles.btn_sell}
                >
                  Sell
                </button>
              </span>

              <span style={{ fontSize: ".8rem", color: "grey" }}>
                Volume (BTC)
                <br />
                <input
                  value={btc}
                  type="number"
                  min="0"
                  step="0.003"
                  onChange={(e) => setBtc(e.target.value)}
                  className={styles.input}
                />
              </span>
              <p style={{ color: "grey", fontWeight: "bold" }}>
                <br />x
              </p>
              <span style={{ fontSize: ".8rem", color: "grey" }}>
                Limit Price (USD)
                <br />
                <input
                  value={formatCurrency(usd)}
                  contentEditable={false}
                  className={styles.input}
                />
              </span>
              <p>
                <br />=
              </p>
              <span style={{ fontSize: ".8rem", color: "grey" }}>
                Total (USD)
                <br />
                <input
                  value={formatCurrency(total)}
                  contentEditable={false}
                  className={styles.input}
                />
              </span>
            </div>
            <span style={{ display: "flex", justifyContent: "center" }}>
              <button
                onClick={() => createOrder()}
                disabled={userDetails?.isLocked || creatingTrade ? true : false}
                style={{
                  marginTop: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "20px",
                  backgroundColor:
                    userDetails?.isLocked || creatingTrade
                      ? "rgba(40,36,182,.5)"
                      : "rgba(40,36,182)",
                  cursor: userDetails?.isLocked ? "not-allowed" : "pointer",
                  alignSelf: "center",
                  border: "0px",
                  padding: "1.2rem",
                  width: "250px",
                  borderRadius: "5px",
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                {creatingTrade ? (
                  <CircularProgress size={15} color="white" />
                ) : (
                  "Trade Crypto (USD)"
                )}
              </button>
              {!userDetails.isLocked && (
                <button
                  onClick={() => setAutoModal(true)}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: ".5rem",
                    marginTop: "20px",
                    marginBottom: "20px",
                    backgroundColor: "rgba(40,36,182)",
                    cursor: "pointer",
                    alignSelf: "center",
                    border: "0px",
                    padding: "1.2rem",
                    width: "50px",
                    borderRadius: "5px",
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  Auto
                </button>
              )}
            </span>

            {userDetails?.isLocked && (
              <p
                style={{
                  fontSize: ".85rem",
                  color: "grey",
                  textAlign: "center",
                }}
              >
                Unlock Your Account to start trading{" "}
                <span
                  onClick={handleClickOpen}
                  style={{
                    color: "black",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                >
                  How?
                </span>
              </p>
            )}
            <div></div>
          </div>
        )}

        <div className={styles.container2}>
          <div className={styles.container2__header}>
            <h4 style={{ color: "grey", margin: 0 }}>Order Book</h4>
            {/* <span title="refresh" > */}
            <MdRefresh
              size={22}
              title="refresh"
              className={refreshing ? styles.spin2 : ""}
              onClick={() => getOrders()}
              style={{ cursor: "pointer" }}
            />
            {/* </span> */}
          </div>
          <div style={{ height: "300px" }}>
            <DataGrid
              getRowId={(row) => row._id}
              rows={orders}
              columns={columns}
              pageSize={25}
              checkboxSelection={false}
              disableSelectionOnClick={true}
              className={classes.root}
            />
          </div>
        </div>
        <br />
        <br />
        <br />
        <Footer />
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <div style={{ padding: "1rem" }}>
          <h3>Unlocking your Account</h3>
          <p style={{ fontSize: ".85rem" }}>
            Every Cryptify account is locked for trading by default. Your
            account is unlocked after your initial deposit, this is a one time
            procedure that will not re-occur. Procced with unlocking your
            account by making your first deposit. <b>Note:</b> minimum deposit
            for your account type is 100 USD.
          </p>
        </div>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
          <Button onClick={() => history.push("/u/funding")} color="primary">
            fund my account
          </Button>
        </DialogActions>
      </Dialog>

      {/* AUTO MODAL */}
      <Dialog
        open={autoModal}
        onClose={() => setAutoModal(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent className={styles.col2}>
          <div
            className={styles.auto_div}
            style={{ width: "400px", height: "500px" }}
          >
            <h2 style={{ color: "grey" }}>Let's Trade For You.</h2>
            <p
              style={{ margin: 0, fontSize: ".85rem", color: "rgb(57,44,178)" }}
            >
              Sit back, relax and watch your profits grow!
            </p>
            <p style={{ textAlign: "center" }}>
              <img src="/trade-for-u.webp" width="50%" />
            </p>
            <p style={{ fontSize: ".9rem", fontWeight: "bold" }}>
              What this means?{" "}
            </p>
            <p style={{ fontSize: ".8rem", lineHeight: "20px" }}>
              Autotrading is a trading plan where buy and sell orders are
              automatically placed based on an underlying system or program.
              These orders are placed when the trade conditions in the
              underlying system or program are met.
            </p>
            <p style={{ fontSize: ".9rem", fontWeight: "bold" }}>
              How we do it
            </p>
            <p style={{ fontSize: ".8rem", lineHeight: "20px" }}>
              Each of our trades are monitored by professionals who take their
              time to ensure substantial profit is made on each trade placed and
              also prevent avoidable losses. Once your account is set to auto
              trade, our world class software takes over from there and you will
              be notified of all important information on your account. Every
              trade, opened or closed can be viewed under 'orders'.{" "}
              <b>Click Start</b> Now to proceed with this action...{" "}
            </p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAutoModal(false)} color="primary">
            Close
          </Button>
          <Button onClick={() => startAutoTrade()} color="primary">
            start now
          </Button>
        </DialogActions>
      </Dialog>

      {/* disalbe auto trade */}
      <Dialog
        open={openDisable}
        onClose={() => setOpenDisable(false)}
        aria-labelledby="form-dialog-title"
      >
        <div style={{ padding: "1rem" }}>
          <h3>Stop Auto Trade</h3>
          <p style={{ fontSize: ".85rem" }}>
            This action will stop your account from being traded and monitored
            <br /> by krakanPro. <b>Proceed?</b>
          </p>
        </div>
        <DialogActions>
          <Button onClick={() => setOpenDisable(false)} color="primary">
            no
          </Button>
          <Button onClick={() => stopAutoTrade()} color="primary">
            yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Trade;
