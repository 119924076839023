import React, { useContext } from 'react'
import DashNavbar from '../../../components/DashNavbar'
import styles from './index.module.css'
import Footer from '../../../components/DashFooter';
import { Helmet } from 'react-helmet'
import { AuthContext } from '../../../contexts/authContextApi';
import { formatCurrency } from '../../../utils/formatCurrency'

function History() {

    const { userDetails } = useContext(AuthContext);

    const toDate=(obj)=>{
        var date = new Date(obj)
        return date.toLocaleString()
    }

    return (
        <>
        <Helmet>
            <title>History - Cryptify</title>
        </Helmet>
        <div className={styles.main}>
            <DashNavbar />
            <div className={styles.bodyHeader}>
                <p>Ledger History</p>
            </div>
            <div className={styles.container2}>
                <div className={styles.container2__header}>
                    <h4 style={{color: 'grey', margin: 0}}>All</h4>
                </div>
                <div className={styles.container2__body}>
                    <div>
                        <p>ID</p>
                        {/* <p>DATE</p> */}
                        <p>TYPE</p>
                        <p>CURRENCY</p>
                        <p>AMOUNT</p>
                        <p>FEE</p>
                        <p>STATUS</p>
                        <p>WALLET</p>
                    </div>
                    {userDetails.transactions.length == 0 && (
                        <div className={styles.container2__body__empty}>
                            <p>No Entries currently available.</p>
                        </div>
                    )}
                    {userDetails.transactions.map((transaction, i)=>(
                        <div>
                            <p style={{textAlign: 'center'}}>{i}</p>
                            {/* <p style={{fontSize: '.75rem'}}>{toDate(transaction.date)}</p> */}
                            {/* <p style={{fontSize: '.75rem'}}>sdfs</p> */}
                            <p style={{textAlign: 'center'}}>{transaction.type}</p>
                            <p style={{textAlign: 'center'}}>{transaction.currency}</p>
                            <p style={{textAlign: 'center'}}>${formatCurrency(transaction.amount)}</p>
                            <p style={{textAlign: 'center'}}>${formatCurrency(transaction.fee)}</p>
                            <p style={{textAlign: 'center'}}>{transaction.status}</p>
                            <p style={{textAlign: 'center'}}>{transaction.wallet == 'bitcoin'? 'BTC':'ETH'}</p>
                        </div>
                    ))}
                </div>
            </div>

          
            <br />
            <br />
            <br />
            <Footer />
        </div>
        </>
    )
}

export default History
