import React, { useContext, useState } from 'react'
import DashNavbar from '../../../components/DashNavbar'
import styles from './index.module.css'
import Footer from '../../../components/DashFooter';
import { Helmet } from 'react-helmet'
import { AuthContext } from '../../../contexts/authContextApi';
import axios from '../../../utils/axios';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CircularProgress } from '@material-ui/core';
import { TextField } from '@material-ui/core'
import Button from '@material-ui/core/Button';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaCheckCircle, FaCheck, FaTimes } from 'react-icons/fa';
import { HiOutlineDatabase } from 'react-icons/hi';
import { AiOutlineSliders } from 'react-icons/ai';
import { useHistory } from 'react-router'


function History() {

    const { userDetails } = useContext(AuthContext);
    const [ pgpKey, setPgpKey ] = useState(userDetails?.pgpKey || '');
    const [ loading, setLoading ] = useState(false);
    const [ msg, setMsg ] = useState([false, ''])
    const [ passMsg, setPassMsg ] = useState([false, ''])
    const [open, setOpen] = useState(false);
    const [accountTypes, showAccountTypes] = useState(false);
    const [ password, setPassword ] = useState('')
    const [ password2, setPassword2 ] = useState('')
    const history = useHistory()
    const [ toolPit, setToolPit ] = useState([0, 'account']);
    const [ btn1Loading, setBtn1Loading ] = useState(false)
    const [ btn2Loading, setBtn2Loading ] = useState(false)
    const [ btn3Loading, setBtn3Loading ] = useState(false)

    let token = localStorage.getItem("krkn_pro");

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const updateSettings=()=>{
        if(pgpKey.length < 451){
            return setMsg([true, 'Pgp Key should be greater than or equal to 451 characters '])
        }
        setLoading(true)
        axios.post('/user/add-pgp',{pgpKey},{
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "x-access-token":token,
        }})
        .then(res=>{
            setLoading(false)
            if(res.data.status){
                window.location.reload()
            }
        })
        .catch(err=>{
            setLoading(false)
            setMsg('Something went wrong, Please try again.')
            if(!err.response.data?.authenticated){
                history.push('/sign-in')
            }
        })
    }

    const updatePassword=()=>{
        if(password.length < 6 || password2.length < 6){
            setPassword('')
            setPassword2('')
            var __=()=>{
                setPassMsg([true, 'Password length should not be less than 6 characters'])
                setTimeout(()=>{
                    setPassMsg([false, ""]);
                }, 4000)
            }
            return __()
        }
        if(password != password2){
            setPassword('')
            setPassword2('')
            var __=()=>{
                setPassMsg([true, 'Password Mismatch'])
                setTimeout(()=>{
                    setPassMsg([false, ""]);
                }, 4000)
            }
            return __()
        }
        axios.post('/user/update-password',{password},{
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "x-access-token":token,
            }
        })
        .then(res=>{
            setPassword('')
            setPassword2('')
            handleClose()
            toast.info("Password successfully changed.",{
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: false,
            });
        })
        .catch(err=>{
            toast.error("Something went wrong, Please try again.",{
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: false,
            });
            if(!err.response.data?.authenticated){
                history.push('/sign-in')
            }
        })
    }

    const changeAccount=(account)=>{
        if(account == 'Basic'){
            setBtn1Loading(true)
        }else if(account == 'Intermediate'){
            setBtn2Loading(true)
        }else if(account == 'Professional'){
            setBtn3Loading(true)
        }
        axios.post('/user/change-account', { account },{
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "x-access-token":token,
            }
        })
        .then(res=>{
            if(account == 'Basic'){
                setBtn1Loading(false)
            }else if(account == 'Intermediate'){
                setBtn2Loading(false)
            }else if(account == 'Professional'){
                setBtn3Loading(false)
            }
            toast.info(res.data.message,{
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: false,
            });
            if(res.data.status){
                setTimeout(()=>{
                    window.location.reload();
                }, 1500)
            }
            
        })
        .catch(err=>{
            if(account == 'Basic'){
                setBtn1Loading(false)
            }else if(account == 'Intermediate'){
                setBtn2Loading(false)
            }else if(account == 'Professional'){
                setBtn3Loading(false)
            }
            toast.error("Something went wrong, Please try again.",{
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: false,
            });
            if(!err.response.data?.authenticated){
                history.push('/sign-in')
            }
        })
    }

    return (
        <>
        <ToastContainer />
        <Helmet>
            <title>Account - Cryptify</title>
        </Helmet>
        <div className={styles.main}>
            <DashNavbar />
            <div className={styles.bodyHeader}>
                <span onClick={()=>setToolPit([0, 'account'])} 
                    style={{
                        color: toolPit[0] == 0? 'black' : 'grey'
                    }}>Account
                </span>
                <span onClick={()=>setToolPit([1, 'change-type'])} 
                    style={{
                        color: toolPit[0] == 1? 'black' : 'grey'
                    }}>Change Account
                </span>
            </div>
            {toolPit[1] == 'account'? (
                <div className={styles.container2}>
                    {/* <div>
                       <p>Username</p>
                       <p>{userDetails?.username} <span style={{ color: 'grey' }}>(A private identifier)</span> <br />
                       <span onClick={()=>handleClickOpen()} style={{ cursor: 'pointer', color: 'blue', fontSize: '.85rem' }}>Change password</span>
                       </p>
                    </div> */}
                    <div>
                       <p>Private Account ID</p>
                       <p style={{fontSize: '.85rem'}}>{userDetails._id} <span style={{ color: 'grey' }}>(A private identifier)</span><br/>
                        <span onClick={()=>handleClickOpen()} style={{ cursor: 'pointer', color: 'blue', fontSize: '.85rem' }}>Change password</span>
                       </p>
                    </div>
                    <div>
                       <p>Account Type</p>
                       <p style={{fontSize: '.85rem'}}>{userDetails.account} <span onClick={()=>setToolPit([1, 'change-type'])} style={{ color: 'grey', textDecoration: 'underline', cursor: 'pointer' }}>(change)</span></p>
                    </div>
                    <div>
                       <p>Email</p>
                       <p style={{fontSize: '.85rem'}}>{userDetails.email} <span style={{ color: 'grey' }}>(Email Address)</span></p>
                    </div>
                    <div>
                       <p>Country</p>
                       <p style={{fontSize: '.85rem'}}>{userDetails?.country}</p>
                    </div>
                    <div style={{borderBottom: '0px'}}>
                       <p style={{width: '100%'}}>Public GPG key <br />
                            <textarea value={pgpKey} onChange={e=>setPgpKey(e.target.value)} style={{width: '100%', border: '.5px solid lightgrey', marginTop: '.7rem', height: '100px'}} />
                       </p>
                    </div>
                    <p style={{color: 'orange', textAlign: 'center', fontSize: '.85rem'}}>{msg}</p>
                        <button disabled={loading? true:false} onClick={()=>updateSettings()} style={{cursor: 'pointer'}} className={styles.update_btn}>
                            {loading? (
                                'Loading ...'
                            ):(
                                'Update Settings'
                            )}
                        </button>
                </div>
            ):(
                <div className={styles.account_types}>
                    <div className={styles.account_type_child}>
                        <div className={styles.account_type_header__1}>
                            <h3 style={{marginBottom: 0}}>Account features and limits</h3>
                            <p style={{fontSize: '.85rem', color: 'grey'}}>Select an account type that best suits your Investment/Trading needs and limits.</p>
                        </div>
                        <div className={styles.account_type_child__body__1}>
                            <br/>
                            <span style={{color: 'blue', fontWeight: 'bold', fontSize: '.9rem'}}><HiOutlineDatabase /> Limits</span>
                            <span style={{fontSize: '.8rem'}}>Starting Amount</span>
                            <span style={{fontSize: '.8rem'}}>Auto Trade</span>
                            <span style={{fontSize: '.8rem'}}>Cryptocurrency Deposits</span>
                            <span style={{fontSize: '.8rem'}}>Withdrawals Per Day</span>
                            <br/>
                            <br/>
                            <span style={{color: 'blue', fontWeight: 'bold', fontSize: '.9rem'}}><AiOutlineSliders /> Features</span>
                            <span style={{fontSize: '.8rem'}}>ROI</span>
                            <span style={{fontSize: '.8rem'}}>Fee</span>
                            <span style={{fontSize: '.8rem'}}>Buy Cryptocurrency</span>
                            <span style={{fontSize: '.8rem'}}>Trade with Margin</span>
                        </div>
                        
                    </div>
                    <div className={styles.account_type_child}>
                        <div style={{backgroundColor: 'lightblue'}}  className={styles.account_type_header}>
                            <h2>Basic</h2>
                            {userDetails.account !== 'Basic' ? (
                                <button disabled={btn1Loading? true:false} onClick={()=>changeAccount('Basic')} className={styles.choose_account_btn}>
                                    {btn1Loading? <CircularProgress size={20}/> : 'choose'}
                                </button>
                            ):(
                                <FaCheckCircle size={25} />
                            )}
                        </div>
                        <div style={{alignItems: 'center'}} className={styles.account_type_child__body}>
                            <br/>
                            <span></span>
                            <span style={{justifyContent: 'center', color: 'black', fontSize: '.8rem'}}>$100 - $500</span>
                            <span style={{justifyContent: 'center', color: 'black', fontSize: '.8rem'}}>5hrs per day</span>
                            <span style={{justifyContent: 'center', color: 'black', fontSize: '.8rem'}}>unlimited</span>
                            <span style={{justifyContent: 'center', color: 'black', fontSize: '.8rem'}}>1</span>
                            <br/>
                            <br/>
                            <span></span>
                            <span style={{justifyContent: 'center', color: 'black', fontSize: '.8rem'}}>~3.5% per account hour</span>
                            <span style={{justifyContent: 'center', color: 'black', fontSize: '.8rem'}}>2%</span>
                            <span style={{justifyContent: 'center', color: 'black', fontSize: '.8rem'}}><FaCheck color="blue" /></span>
                            <span style={{justifyContent: 'center', color: 'black', fontSize: '.8rem'}}><FaTimes color="blue" /></span>
                        </div>
                    </div>
                    <div className={styles.account_type_child}>
                        <div style={{backgroundColor: 'pink'}} className={styles.account_type_header}>
                            <h2>Intermediate</h2>
                            {userDetails.account !== 'Intermediate' ? (
                                <button disabled={btn2Loading? true:false} onClick={()=>changeAccount('Intermediate')} className={styles.choose_account_btn}>
                                    {btn2Loading? <CircularProgress size={20}/> : 'choose'}
                                </button>
                            ):(
                                <FaCheckCircle size={25} />
                            )}
                        </div>
                        <div className={styles.account_type_child__body}>
                            <br/>
                            <span></span>
                            <span style={{justifyContent: 'center', color: 'black', fontSize: '.8rem'}}>$500 - $2000</span>
                            <span style={{justifyContent: 'center', color: 'black', fontSize: '.8rem'}}>10hrs per day</span>
                            <span style={{justifyContent: 'center', color: 'black', fontSize: '.8rem'}}>unlimited</span>
                            <span style={{justifyContent: 'center', color: 'black', fontSize: '.8rem'}}>5</span>
                            <br/>
                            <br/>
                            <span></span>
                            <span style={{justifyContent: 'center', color: 'black', fontSize: '.8rem'}}>~7.5% per account hour</span>
                            <span style={{justifyContent: 'center', color: 'black', fontSize: '.8rem'}}>5.5%</span>
                            <span style={{justifyContent: 'center', color: 'black', fontSize: '.8rem'}}><FaCheck color="blue" /></span>
                            <span style={{justifyContent: 'center', color: 'blue', fontSize: '.8rem'}}>limited</span>
                        </div>
                        
                    </div>
                    <div className={styles.account_type_child}>
                        <div style={{backgroundColor: 'rgb(240,154,108)'}} className={styles.account_type_header}>
                            <h2>Pro</h2>
                            {userDetails.account !== 'Professional' ? (
                                <button disabled={btn3Loading? true:false} onClick={()=>changeAccount('Professional')} className={styles.choose_account_btn}>
                                    {btn3Loading? <CircularProgress size={20}/> : 'choose'}
                                </button>
                            ):(
                                <FaCheckCircle size={25} />
                            )}
                        </div>
                        <div className={styles.account_type_child__body}>
                            <br/>
                            <span></span>
                            <span style={{justifyContent: 'center', color: 'black', fontSize: '.8rem'}}>$2,000+</span>
                            <span style={{justifyContent: 'center', color: 'black', fontSize: '.8rem'}}>20hrs per day</span>
                            <span style={{justifyContent: 'center', color: 'black', fontSize: '.8rem'}}>unlimited</span>
                            <span style={{justifyContent: 'center', color: 'black', fontSize: '.8rem'}}>unlimited</span>
                            <br/>
                            <br/>
                            <span></span>
                            <span style={{justifyContent: 'center', color: 'black', fontSize: '.8rem'}}>~10.5% per account hour</span>
                            <span style={{justifyContent: 'center', color: 'black', fontSize: '.8rem'}}>7.5%</span>
                            <span style={{justifyContent: 'center', color: 'black', fontSize: '.8rem'}}><FaCheck color="blue" /></span>
                            <span style={{justifyContent: 'center', color: 'blue', fontSize: '.8rem'}}>limited</span>
                        </div>
                    </div>
                </div>
            )}
            
            <br />
            <br />
            <br />
            <Footer />
        </div>
        <Dialog open={open} aria-labelledby="form-dialog-title">
            <div style={{width: '400px', padding: '1rem'}}>
                <h3>Update Password</h3>
                {passMsg[0] && (
                    <p style={{fontSize: '.85rem', color: 'orange'}}>{passMsg[1]}</p>
                )}
                <TextField value={password} onChange={e=>setPassword(e.target.value)} type="password" label="New Password" style={{width: '100%', marginBottom: '10px'}} />
                <TextField value={password2} onChange={e=>setPassword2(e.target.value)} type="password" label="Re-Enter Password" style={{width: '100%'}} />
                <p style={{textAlign: 'center', fontSize: '.85rem', color: 'grey'}}>Be sure to login with your new password</p>
            </div>
            
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Close
                </Button>
                <Button onClick={()=>updatePassword()} color="primary">
                    Update password
                </Button>
            </DialogActions>
        </Dialog>
        </>
    )
}

export default History
