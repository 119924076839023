// SIGN UP PAGE
import React, { useState, useEffect } from "react";
import styles from "./index.module.css";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import { Helmet } from "react-helmet";
import { useHistory, Redirect } from "react-router-dom";
import axios from "../../utils/axios";
import { CircularProgress } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Signin() {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [emailTo, setEmailTo] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setEmailTo("");
    setOpen(false);
  };

  const SignIn = async (e) => {
    e.preventDefault();
    if (!email || !password) {
      setMessage("Both fields are required");
      return setTimeout(() => {
        setMessage("");
      }, 4000);
    }
    setLoading(true);
    axios
      .post("/user/auth/signin", { email, password })
      .then((res) => {
        console.log(res.data);
        if (res.data.session) {
          setMessage(res.data.message);
          setTimeout(() => {
            setMessage("");
          }, 4000);
          localStorage.setItem("krkn_pro", res.data.token);
          setLoading(false);
          history.push("/u/trade");
        } else {
          setMessage(res.data.message);
          setTimeout(() => {
            setMessage("");
          }, 4000);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err.message);
        setMessage(err.message);
        setTimeout(() => {
          setMessage("");
        }, 3000);
        setLoading(false);
      });
  };

  const sendResetLink = () => {
    if (emailTo.length < 5) {
      return toast.warning("Enter a valid email to continue", {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: false,
      });
    }
    setIsLoading(true);
    axios
      .post("/user/send-password-reset-link", { email: emailTo })
      .then((res) => {
        if (res.data.status) {
          handleClose();
          toast.info(res.data.message, {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
          });
          setIsLoading(false);
        } else {
          toast.warning(res.data.message, {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: false,
          });
          setIsLoading(false);
        }
      })
      .catch(() => {
        setIsLoading(false);
        toast.error("Something went wrong, try again.", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: false,
        });
      });
  };

  return (
    <>
      <ToastContainer />
      <main className={styles.main}>
        <Helmet>
          <title>Sign In - Cryptify</title>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Helmet>
        <form onSubmit={(e) => SignIn(e)}>
          <p style={{ textAlign: "center" }}>
            <img
              onClick={() => history.push("/")}
              src="/logo.png"
              width="15%"
            />
          </p>
          <p style={{ fontSize: "2rem" }}>
            Sign In to <b>Cryptify</b>
            {/* <p style={{fontSize: '2rem'}}>Sign In to <b>Kraken<span style={{color: 'rgb(255,139,139)'}}>Pro</span></b> */}
            <p style={{ fontSize: ".85rem", color: "orange" }}>{message}</p>
          </p>
          <TextField
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            label="Email"
            style={{ width: "100%", marginBottom: "35px" }}
          />
          <TextField
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            label="Password"
            style={{ width: "100%" }}
          />

          <div
            style={{
              marginTop: "20px",
              width: "100",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <p
              onClick={handleClickOpen}
              style={{ cursor: "pointer", color: "rgb(88,65,216)" }}
            >
              Help
            </p>
            <p
              onClick={() => history.push("/sign-up")}
              style={{ cursor: "pointer", color: "rgb(88,65,216)" }}
            >
              Create Account
            </p>
            <button
              disabled={loading ? true : false}
              className={styles.signin_btn}
            >
              {loading && (
                <CircularProgress size={22} style={{ color: "white" }} />
              )}
              {!loading && "Sign In"}
            </button>
          </div>
        </form>
      </main>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <div style={{ width: "300px", padding: "1rem" }}>
          <h2 style={{ color: "grey" }}>Reset Password</h2>
          <p style={{ fontSize: ".85rem" }}>
            You will a receive a password reset link from us, be sure to check
            your spam folder incase.
          </p>
          <TextField
            value={emailTo}
            onChange={(e) => setEmailTo(e.target.value)}
            label="Email"
            style={{ width: "100%", marginBottom: "35px" }}
          />
        </div>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
          {isLoading ? (
            <CircularProgress size={20} />
          ) : (
            <Button onClick={() => sendResetLink()} color="primary">
              reset password
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Signin;
