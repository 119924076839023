import React from 'react'
import styles from './index.module.css'
import { useHistory } from 'react-router-dom'


function Footer() {

    const history = useHistory();

    return (
        <div className={styles.footer}>
            <div className={styles.footer__col1}>
                <div>
                    <h2 style={{color: 'white'}}>Cryptify</h2>
                    {/* <h2 style={{color: 'white'}}>Cryptify<span style={{color: 'rgb(255,139,139)'}}>Pro</span></h2> */}
                </div>
                <div>
                    <h2 style={{color: 'rgb(167,156,235)'}}>Features</h2>
                    <a style={{color: 'white', fontSize: '.85rem', fontWeight: 'bold', margin: '.3rem', textDecoration: 'none'}} href="https://www.kraken.com/en-us/features/24-7-support" >24/7 Support</a>
                    <a style={{color: 'white', fontSize: '.85rem', fontWeight: 'bold', margin: '.3rem', textDecoration: 'none'}} href="https://www.kraken.com/en-us/features/security" >Security</a>
                    <a style={{color: 'white', fontSize: '.85rem', fontWeight: 'bold', margin: '.3rem', textDecoration: 'none'}} href="https://www.kraken.com/en-us/features/indices" >Indices</a>
                    <a style={{color: 'white', fontSize: '.85rem', fontWeight: 'bold', margin: '.3rem', textDecoration: 'none'}} href="https://www.kraken.com/en-us/features/staking-coins" >Staking</a>
                    <a style={{color: 'white', fontSize: '.85rem', fontWeight: 'bold', margin: '.3rem', textDecoration: 'none'}} href="https://www.kraken.com/redirect?url=https%3A%2F%2Fsupport.kraken.com%2Fhc%2Fen-us" >Support</a>
                    <a style={{color: 'white', fontSize: '.85rem', fontWeight: 'bold', margin: '.3rem', textDecoration: 'none'}} href="https://www.kraken.com/redirect?url=https%3A%2F%2Fdocs.kraken.com%2Fwebsockets%2F" >Websockets</a>
                </div>
                <div>
                    <h2 style={{color: 'rgb(167,156,235)'}}>Learn</h2>
                    <a style={{color: 'white', fontSize: '.85rem', fontWeight: 'bold', margin: '.3rem', textDecoration: 'none'}} href="https://www.kraken.com/redirect?url=https%3A%2F%2Fblog.kraken.com%2F">Blog</a>
                    <a style={{color: 'white', fontSize: '.85rem', fontWeight: 'bold', margin: '.3rem', textDecoration: 'none'}} href="https://www.kraken.com/en-us/learn/crypto-guides">Crypto Guides</a>
                    <a style={{color: 'white', fontSize: '.85rem', fontWeight: 'bold', margin: '.3rem', textDecoration: 'none'}} href="https://www.kraken.com/en-us/learn/podcast">Podcast</a>
                    <a style={{color: 'white', fontSize: '.85rem', fontWeight: 'bold', margin: '.3rem', textDecoration: 'none'}} href="https://www.kraken.com/en-us/learn/buy-bitcoin-btc">How To Buy Bitcoin</a>

                </div>
                <div>
                    <h2 style={{color: 'rgb(167,156,235)'}}>Account</h2>
                    <p onClick={()=>history.push('/u/account')} style={{color: 'white', fontSize: '.85rem', cursor: 'pointer', fontWeight: 'bold', margin: '.3rem', textDecoration: 'none'}}>Your Account</p>
                    <p onClick={()=>history.push('/sign-in')} style={{cursor: 'pointer', color: 'white', fontSize: '.85rem', fontWeight: 'bold', margin: '.3rem', textDecoration: 'none'}}>Sign Out</p>
                </div>
            </div>
        </div>
    )
}

export default Footer
