import React,{ useState, useEffect, useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import axios from '../../utils/axios'
import { AuthContext } from '../../contexts/authContextApi'
import { baseurl } from '../../utils/url';
import { useHistory } from 'react-router-dom'
import styles from './index.module.css'


  const ProtectedRoute = ({ component: Component, ...rest }) => {


    // const [auth, setAuth] = useState(false);
    const [isTokenValidated, setIsTokenValidated] = useState(false);
    const {auth, setAuth, setUserDetails} = useContext(AuthContext);
    const history = useHistory()

    function refreshValidatePage(){
      let token = localStorage.getItem("krkn_pro");
      if (token) {
        axios.get('/user/isuserauth', {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "x-access-token":token,
          },
          // body: JSON.stringify({ token })
        })
        .then((res) => {
          return res
        })
        .then((json) => {
          if (json.data.authenticated) {
            setAuth(true);
            setUserDetails(json.data.details)
          }else{
            setAuth(false);
            history.push('/sign-in')
            localStorage.removeItem("krkn_pro"); 
          }
        })
        .catch((err) => {
          setAuth(false);
          console.log(err)
          localStorage.removeItem("krkn_pro");
        })
        .then(() => setIsTokenValidated(true));
      } else {
        setIsTokenValidated(true); // in case there is no token
      }
    }

    
      // send jwt to API to see if it's valid when navigating to a protected route
    useEffect(() => {
      refreshValidatePage();
    }, [])

  if (!isTokenValidated) return (
    <div className={styles.the_box}>
       <span className={styles.loader}></span>
    </div>
  )

  return (<Route {...rest}
    render={(props) => {
      return auth ? <Component {...props} /> : <Redirect to="/" />
    }} />)
  }
export default ProtectedRoute;