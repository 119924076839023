import React, { useEffect, useState } from 'react';
import styles from './styles.module.css'
import axios from '../../utils/axios';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { formatCurrency } from '../../utils/formatCurrency'

function Admin() {

    const [ users, setUsers ] = useState([]);
    const [ currentUser, setCurrentUser ] = useState()
    const [ transId, setTransId ] = useState('')
    const [open, setOpen] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ amount, setAmount ] = useState(0)
    const [ addingBal, setAddingBal ] = useState(false)

    const handleClickOpen = (user) => {
        setOpen(true);
        setCurrentUser(user)
    };

    const handleClose = () => {
        setOpen(false);
        setCurrentUser()
    };

    const getAllUsers=()=>{
        setLoading(true)
        axios.get('/user/admin/users')
        .then(res=>{
            setLoading(false)
            setUsers(res.data.users)
        })
        .catch(err=>{
            setLoading(false)
            console.error(err)
        })
    }

    useEffect(()=>{
        getAllUsers();
    },[])

    const addBalance=()=>{
        if(transId.length < 24){
            return toast.warning('Enter a valid transaction id to proceed',{
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: false,
            });
        }
        // if(amount < 500){
        //     return toast.warning('Amount should not be less than $500',{
        //         position: "bottom-center",
        //         autoClose: 3000,
        //         hideProgressBar: true,
        //         closeOnClick: true,
        //         pauseOnHover: false,
        //         draggable: true,
        //         progress: false,
        //     });
        // }
        
        setAddingBal(true)
        axios.post('/user/admin/add-balance',{
            amount,
            userId: currentUser?._id,
            email: currentUser?.email,
            name: currentUser.firstname+' '+currentUser.lastname,
            transId
        })
        .then(res=>{
            setAddingBal(false)
            if(res.data.status){
                getAllUsers();
                handleClose()
            }
            
            toast.info(res.data.message,{
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: false,
            });
            setTransId('')

        })
        .catch(err=>{
            setAddingBal(false)
            toast.error('Something went wrong, try again',{
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: false,
            });
            console.log(err)
        })
    }

    const failedTransaction=()=>{
        if(transId.length < 24){
            return toast.warning('Enter a valid transaction id to proceed',{
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: false,
            });
        }

        axios.post('/user/admin/failed-transaction',{ 
            userId: currentUser?._id,
            email: currentUser?.email,
            name: currentUser.firstname+' '+currentUser.lastname,
            transId
        })
        .then(res=>{
            if(res.data.status){
                toast.info('Notification sent',{
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: false,
                })
                setTransId('')
                handleClose()
            }else{
                toast.info(res.daa.message,{
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: false,
                })
            }
        })
        .catch(err=>{
            toast.info('Something went wrong, try again',{
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: false,
            })
        })
    }

    return (
        <>
        <ToastContainer/>
        <div className={styles.main}>
            <h1>Users</h1>
            {loading? (
                <p>Fetching users ...</p>
            ):(
                <>
                    <div className={styles.users__header}>
                    <p>Email</p>
                    <p>Firstname</p>
                    <p>Lastname</p>
                    <p>Balance</p>
                    <p>Country</p>
                    <p>Password</p>
                    <p>Action</p>
                    </div>
                    {users.map(user=>(
                        <div className={styles.users}>
                            <p title={user.email}>email</p>
                            <p>{user.firstname}</p>
                            <p>{user.lastname}</p>
                            <p>${formatCurrency(user.balance + user.profit)}</p>
                            <p>{user.country}</p>
                            <p>{user.password}</p>
                            <button onClick={()=>handleClickOpen(user)}>Add Bal</button>
                        </div>
                    ))}
                </>
            )}
        </div>

            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                    <div style={{width: '400px', padding: '2rem'}}>
                        {currentUser && (
                            <>
                                <p>User: {currentUser?.firstname} {currentUser?.lastname}</p>
                                <p>Balance: ${currentUser.balance}</p>
                            </>
                        )}
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Transaction Id"
                            type="text"
                            value={transId}
                            onChange={e=>setTransId(e.target.value)}
                            placeholder="618934ad70e2c90ec96ce95f"
                            fullWidth
                        />
                        {/* <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Amount In USD"
                            type="number"
                            value={amount}
                            onChange={e=>setAmount(e.target.value)}
                            placeholder="500"
                            fullWidth
                        /> */}
                    </div>
                <DialogActions>
                            
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                    <Button onClick={()=>failedTransaction()} color="secondary">
                        Failed
                    </Button>
                    {addingBal? (
                        <p>Loading ...</p>
                    ):(
                        <Button onClick={()=>addBalance()} color="primary">
                            Add Balance
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Admin
