import React, { useEffect, useState, useContext } from 'react';
import styles from './index.module.css';
import { useHistory } from 'react-router-dom'
import axios from 'axios';
import { AuthContext } from '../../contexts/authContextApi';
import { formatCurrency } from '../../utils/formatCurrency'

function DashNavbar() {

    const history = useHistory()
    const { userDetails, btcInfo, setBtcInfo, ethInfo, setEthInfo, setXrpInfo, setAlgoInfo, setSolInfo } = useContext(AuthContext)
    const colors = ['green', 'red', 'green']

    const GetBtcRate = async () =>{
        const btc = await axios.get('https://blockchain.info/ticker')
        const eth = await axios.get('https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD')
        setBtcInfo(btc.data.USD)
        setEthInfo(eth.data)
    }

    useEffect(()=>{
        GetBtcRate();
        (async()=>{
            const xrp = await axios.get('https://min-api.cryptocompare.com/data/price?fsym=XRP&tsyms=USD')
            const algo = await axios.get('https://min-api.cryptocompare.com/data/price?fsym=ALGO&tsyms=USD')
            const sol = await axios.get('https://min-api.cryptocompare.com/data/price?fsym=SOL&tsyms=USD')
            setXrpInfo(xrp.data)
            setAlgoInfo(algo.data)
            setSolInfo(sol.data)
        })()
        setInterval(()=>{
            GetBtcRate();
        }, 3000)
    },[])

    return (
        <>
        <nav className={styles.nav_one}>
            <div  className={styles.nav_one__main}>
                <div>
                    <img onClick={() => history.push('/u/trade')} src="/logo.png" width="35px" style={{ cursor: "pointer", margin: '.5rem' }} />
                    <p onClick={()=>history.push('/u/trade')}>Trade</p>
                    <p onClick={()=>history.push('/u/earn')}>Earn</p>
                    <p onClick={()=>history.push('/u/funding')}>Funding</p>
                    <p onClick={()=>history.push('/u/history')}>History</p>
                    <p><a style={{color: 'white', textDecoration: 'none'}} href="https://support.kraken.com/hc/en-us">Support</a></p>
                    <button 
                        onClick={(e) => {
                            e.preventDefault();
                            history.push('/u/funding')
                            // window.open('https://buy.coingate.com/?utm_source=coingate&utm_medium=menu_button&utm_campaign=checkout', '_blank');
                        }}
                        className={styles.buy_crypto_btn}>Fund wallet
                    </button>
                </div>
                <div>
                {/* {JSON.stringify(userDetails)} */}
                    <p style={{ textAlign: 'right'}}>
                        <b style={{fontSize: '.8rem'}}>Available Balances:</b><br />
                        {/* 0.00000 BTC<br /> */}
                        <span style={{fontSize: '.75rem'}}>{btcInfo?.buy? ((userDetails?.balance + userDetails.profit) / btcInfo?.buy).toFixed(5) : '--'} BTC</span><br/>
                        <span style={{fontSize: '.75rem'}}>{userDetails?.balance == 0? '0.00' : formatCurrency(userDetails?.balance + userDetails.profit)} USD</span>
                    </p>
                    <span onClick={()=>history.push('/u/account')} style={{ cursor: 'pointer', backgroundColor: 'white', textTransform: 'uppercase', color: 'blue', borderRadius: '50%', padding: '.5rem'}}>{userDetails?.firstname.substring(0,1) + userDetails?.lastname.substring(0,1)}</span>
                </div>
            </div>
        </nav>
        <nav className={styles.nav_two}>
            <div  className={styles.nav_two__main}>
                <p style={{color: 'white', fontSize: '.8rem'}}>
                    <span style={{color: 'lightgrey'}}>Market</span><br />
                    BTC/USD
                </p>
                <span style={{height: '30px', width: '.5px', backgroundColor: 'grey'}} />
                {btcInfo && (
                    <p style={{color: 'white', fontSize: '.8rem'}}>
                        <span style={{color: 'lightgrey'}}>Last 15m</span><br />
                        {formatCurrency(btcInfo["15m"])} USD
                    </p>
                )}
                <p style={{color: 'white', fontSize: '.8rem'}}>
                    <span style={{color: 'lightgrey'}}>Buy</span><br />
                    {formatCurrency(btcInfo?.buy)} USD
                </p>
                <p style={{color: 'white', fontSize: '.8rem'}}>
                    <span style={{color: 'lightgrey'}}>Sell</span><br />
                    {formatCurrency(btcInfo?.sell)} USD
                </p>
                <p style={{color: 'white', fontSize: '.8rem'}}>
                    <span style={{color: 'lightgrey'}}>Weighted avg.</span><br />
                    {btcInfo?.buy ? (btcInfo?.buy + 570.3).toFixed(3) : '--'}
                </p>
                <p style={{color: 'white', fontSize: '.8rem'}}>
                    <span style={{color: 'lightgrey'}}>24h low/high</span><br />
                    {formatCurrency(btcInfo?.buy)} USD {'-'} {formatCurrency(btcInfo?.sell)} USD
                </p>
            </div>
        </nav>
        </>
    )
}

export default DashNavbar
