// SIGN UP PAGE
import React, { useState, useEffect } from 'react'
import styles from './styles.module.css'
import { TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import { Helmet } from 'react-helmet'
import { useHistory, Redirect } from 'react-router-dom'
import axios from '../../utils/axios';
import { CircularProgress } from '@material-ui/core';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ForgotPassword(props) {

    const history = useHistory();
    const [ email, setEmail ] = useState('')
    const token = props?.match?.params?.token;
    const [ password, setPassword ] = useState('')
    const [ failed, setFailed ] = useState(false);
    const [ password2, setPassword2 ] = useState('')
    const [ message, setMessage ] = useState('');
    const [ loading, setLoading ] = useState(false)

    useEffect(()=>{
        (async ()=>{
            axios.get(`/user/forgot-password-verify/${token}`)
            .then(res=>{
                if(res.data.status){
                    setEmail(res.data.email)
                }else{
                    setFailed(true)
                    setFailed(true)
                }
            })
            .catch(()=>{
                setFailed(true)
            })
        })()
    },[])

    const CreatePassword = async (e)=>{
        e.preventDefault();
        if(!password || !password2){
            return toast.warning('Both fields are required',{
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: false,
            });
        }
        if(password !== password2){
            return toast.warning('Password Mismatch',{
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: false,
            });
        }
        if(password.length < 6){
            return toast.warning('New password should be atleast 6 characters',{
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: false,
            });
        }
        setLoading(true)
        axios.post('/user/reset-forgotten-password', {email, password})
        .then(res=>{
            if(res.data.status){
                toast.info(res.data.message+', Signing in... ',{
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: false,
                });
                setLoading(false)

                // SIGN IN AFTER CHANGE
                setTimeout(()=>{
                    axios.post('/user/auth/signin', {email, password})
                    .then(res=>{
                        console.log(res.data);
                        if(res.data.session){
                            setMessage(res.data.message)
                            setTimeout(()=>{
                                setMessage('')
                            },4000)
                            localStorage.setItem('krkn_pro', res.data.token)
                            setLoading(false)
                            history.push('/u/trade')
                        }else{
                            setMessage(res.data.message)
                            setTimeout(()=>{
                                setMessage('')
                            },4000)
                            setLoading(false)
                        }
                    })
                    .catch(err=>{
                        console.log(err.message);
                        setMessage(err.message)
                        setTimeout(()=>{
                            setMessage('')
                        },3000)
                        setLoading(false)
                    })
                }, 2000)
                
            }else{
                toast.warning(res.data.message,{
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: false,
                });
                setLoading(false)
            }
            
        })
        .catch(err=>{
            toast.error('Something went wrong, try again',{
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: false,
            });
            setLoading(false)
        })
    }

   

    return (
        <>
        <ToastContainer />
        <main className={styles.main}>
            {/* <nav>
                <img src="logo.png" width="3%" />
                <img src="logo.png" width="3%" />
            </nav> */}
            <Helmet>
                <title>Reset Password - Cryptify</title>
                <meta name="viewport" content="width=device-width, initial-scale=1" />

            </Helmet>
            <form onSubmit={e=>CreatePassword(e)}>
                {failed? (
                    <div style={{border: '5px solid lightgrey', textAlign: 'center', marginLeft: 'auto', marginRight: 'auto', width: '80%', backgroundColor: 'pink', borderRadius: '10px'}}>
                        <p>Invalid or Expired Reset Link</p>
                    </div>
                ):(
                    <>
                    <p onClick={()=>history.push('/')} style={{textAlign: 'center'}}><img src="/logo.png" width="15%" /></p>
                    <p style={{fontSize: '1.5rem', textAlign: 'center'}}>Create a new Password</p>
                    <p style={{fontSize: '.85rem', color: 'orange'}}>{message}</p>

                    <TextField value={email} disabled={true} type="email" label="Email" style={{width: '100%'}} />
                    <br />
                    <TextField value={password} onChange={e=>setPassword(e.target.value)} type="password" label="New Password" style={{width: '100%'}} />
                    <br />
                    <TextField value={password2} onChange={e=>setPassword2(e.target.value)} type="password" label="Re-enter Password" style={{width: '100%'}} />
                    
                
                    <div style={{marginTop: '20px', width: '100', display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                        {/* <p style={{color: 'rgb(88,65,216)'}}>Help</p> */}
                        {/* <p onClick={()=>history.push('/sign-up')} style={{cursor: 'pointer', color: 'rgb(88,65,216)'}}>Create Account</p> */}
                        <button disabled={loading? true : false} className={styles.signin_btn} >
                            {loading && <CircularProgress size={22} style={{color: 'white'}} />}
                            {!loading && 'Create Password'}
                        </button>
                    </div>
                    </>
                )}
                
            </form>
        </main>
        </>
    )
}

  

export default ForgotPassword


