import React from 'react'
import styles from './index.module.css'
import { useHistory } from 'react-router-dom'

function Footer() {

    const history = useHistory()

    return (
        <div className={styles.footer}>
        <div className={styles.footer__col1}>
            <div>
                <h2 style={{color: 'white', textAlign: 'center'}}>
                    Take your crypto <br/> game to the next level.
                </h2>
                <button onClick={()=>history.push('/sign-up')} style={{width: '100px', backgroundColor: 'white', color: 'blue', fontWeight: 'bold', border: '2px solid white', marginBottom: '10px', padding: '.5rem', borderRadius: '20px'}}>Sign Up</button>
                <button onClick={()=>history.push('/sign-in')} style={{width: '100px', backgroundColor: 'transparent', color: 'white', fontWeight: 'bold', border: '2px solid white', padding: '.5rem', borderRadius: '20px'}}>Sign In</button>
            </div>
            <div>
                <h2 style={{color: 'rgb(167,156,235)'}}>Features</h2>
                <a style={{color: 'white', fontSize: '.85rem', fontWeight: 'bold', margin: '.3rem', textDecoration: 'none'}} href="https://www.kraken.com/en-us/features/24-7-support" >24/7 Support</a>
                <a style={{color: 'white', fontSize: '.85rem', fontWeight: 'bold', margin: '.3rem', textDecoration: 'none'}} href="https://www.kraken.com/en-us/features/security" >Security</a>
                <a style={{color: 'white', fontSize: '.85rem', fontWeight: 'bold', margin: '.3rem', textDecoration: 'none'}} href="https://www.kraken.com/en-us/features/indices" >Indices</a>
                <a style={{color: 'white', fontSize: '.85rem', fontWeight: 'bold', margin: '.3rem', textDecoration: 'none'}} href="https://www.kraken.com/en-us/features/staking-coins" >Staking</a>
                <a style={{color: 'white', fontSize: '.85rem', fontWeight: 'bold', margin: '.3rem', textDecoration: 'none'}} href="https://www.kraken.com/redirect?url=https%3A%2F%2Fsupport.kraken.com%2Fhc%2Fen-us" >Support</a>
                <a style={{color: 'white', fontSize: '.85rem', fontWeight: 'bold', margin: '.3rem', textDecoration: 'none'}} href="https://www.kraken.com/redirect?url=https%3A%2F%2Fdocs.kraken.com%2Fwebsockets%2F" >Websockets</a>
            </div>
            <div>
                <h2 style={{color: 'rgb(167,156,235)'}}>Learn</h2>
                <a style={{color: 'white', fontSize: '.85rem', fontWeight: 'bold', margin: '.3rem', textDecoration: 'none'}} href="https://www.kraken.com/redirect?url=https%3A%2F%2Fblog.kraken.com%2F">Blog</a>
                <a style={{color: 'white', fontSize: '.85rem', fontWeight: 'bold', margin: '.3rem', textDecoration: 'none'}} href="https://www.kraken.com/en-us/learn/crypto-guides">Crypto Guides</a>
                <a style={{color: 'white', fontSize: '.85rem', fontWeight: 'bold', margin: '.3rem', textDecoration: 'none'}} href="https://www.kraken.com/en-us/learn/podcast">Podcast</a>
                <a style={{color: 'white', fontSize: '.85rem', fontWeight: 'bold', margin: '.3rem', textDecoration: 'none'}} href="https://buy.coingate.com/?utm_source=coingate&utm_medium=menu_button&utm_campaign=checkout">Where To Buy Bitcoin</a>

            </div>
            <div>
                <h2 style={{color: 'rgb(167,156,235)'}}>About</h2>
                <a style={{color: 'white', fontSize: '.85rem', fontWeight: 'bold', margin: '.3rem', textDecoration: 'none'}} href="https://www.kraken.com/en-us/why-kraken">Why Kraken</a>
                <a style={{color: 'white', fontSize: '.85rem', fontWeight: 'bold', margin: '.3rem', textDecoration: 'none'}} href="https://www.kraken.com/en-us/institutions">Institutions</a>
                <a style={{color: 'white', fontSize: '.85rem', fontWeight: 'bold', margin: '.3rem', textDecoration: 'none'}} href="https://www.kraken.com/redirect?url=https%3A%2F%2Fsupport.kraken.com%2Fhc%2Fen-us%2Frequests%2Fnew">Contact</a>
                <a style={{color: 'white', fontSize: '.85rem', fontWeight: 'bold', margin: '.3rem', textDecoration: 'none'}} href="https://www.kraken.com/redirect?url=https%3A%2F%2Fsupport.kraken.com%2Fhc%2Fen-us%2Farticles%2F360027545252-Kraken-Affiliate-program">Affiliates</a>
                <a style={{color: 'white', fontSize: '.85rem', fontWeight: 'bold', margin: '.3rem', textDecoration: 'none'}} href="https://www.kraken.com/en-us/bank">Kraken Bank</a>
                <a style={{color: 'white', fontSize: '.85rem', fontWeight: 'bold', margin: '.3rem', textDecoration: 'none'}} href="https://www.kraken.com/en-us/features/grants">Grants</a>
            </div>
        </div>
        <div style={{width: '70%', height: '.5px', backgroundColor: 'rgba(167,156,235,.5)'}} />
        <div>
                <h2 style={{color: 'white'}}>Cryptify</h2>
                {/* <h2 style={{color: 'white'}}>Kraken<span style={{color: 'rgb(255,139,139)'}}>Pro</span></h2> */}
                <div>
                    <p style={{color: 'white', fontSize: '.75rem', fontWeight: 'lighter', margin: '.3rem', textDecoration: 'none'}}>&copy; 2011 - 2021 Payward, Inc</p>
                    <a style={{color: 'white', fontSize: '.75rem', fontWeight: 'lighter', margin: '.3rem', textDecoration: 'underline'}} href="https://www.kraken.com/en-us/legal/privacy">Privacy Notice</a>
                    <a style={{color: 'white', fontSize: '.75rem', fontWeight: 'lighter', margin: '.3rem', textDecoration: 'underline'}} href="https://www.kraken.com/en-us/legal">Terms of Service</a>
                    <p style={{color: 'white', fontSize: '.75rem', fontWeight: 'lighter', margin: '.3rem', textDecoration: 'none'}}>A subsidiary of Kraken</p>
                </div>
        </div>
    </div>
    )
}

export default Footer
