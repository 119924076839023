import React, { useContext, useEffect, useState } from 'react'
import DashNavbar from '../../../components/DashNavbar'
import styles from './index.module.css'
import Footer from '../../../components/DashFooter';
import { Helmet } from 'react-helmet';
import { AuthContext } from '../../../contexts/authContextApi';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router'

function Earn() {

    const { userDetails } = useContext(AuthContext);
    const history =  useHistory()

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <> 
        <Helmet>
            <title>Earn - Cryptify</title>
        </Helmet>
        <div className={styles.main}>
            <DashNavbar />
            <div className={styles.bodyHeader}>
                <p>Staking</p>
            </div>
            <div className={styles.container}>
                <div>
                    <h3>Staking Coins</h3>
                    <p>Staking is a great way to maximize holdings that would otherwise be sitting in your Kraken account. Once you have staked your assets you can earn staking rewards on top of your holdings and, in applicable assets, grow them further by compounding those future rewards. Learn more about staking</p>
                </div>
                <div>
                    <p><b>Get assets you can stake</b><br /> 
                       Buy or fund your Kraken account with one of the assets eligible for staking below.
                    </p>
                    <p><b>Select an asset to stake</b><br />
                       Choose from the eligible assets in your spot wallet.
                    </p>
                    <p><b>Earn rewards</b><br />
                       Receive weekly or bi-weekly rewards depending on the staked asset.
                    </p>
                </div>
            </div>

            <div className={styles.container}>
                <div>
                    <h3>Limited account functionality</h3>
                    <p>Get verified to a higher account level to fund your account with cash and increase your account funding limits.</p>
                </div>
                <div>
                    <button onClick={()=>history.push('/u/account')} className={styles.upgrade_btn}>Upgrade Account</button>
                </div>
            </div>

            <Footer />
        </div>

        {/* account upgrade */}
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogContent>
                    <div style={{padding: '1rem'}}>
                        <h3>Unlocking your Account</h3>
                        <p style={{fontSize: '.85rem'}}>Every Cryptify account is locked for trading by default. Your account is unlocked after your initial deposit, this is a one time procedure that will not re-occur. Procced with unlocking your account by making your first deposit. <b>Note:</b> minimum deposit for any account type is 100 USD.</p>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                    <Button color="primary">
                        fund my account
                    </Button>
                </DialogActions>
        </Dialog>
        </>
    )
}

export default Earn
