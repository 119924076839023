import React from 'react';
import styles from './navbar.module.css';
import { useHistory } from 'react-router-dom';

function Navbar() {

    const history = useHistory()

    return (
        <div className={styles.main}>
            <h1 style={{color: 'white', display: 'flex', alignItems: 'center'}}>
                <img className={styles.img} src="/logo.png" /><span className={styles.logo_text}>Cryptify</span>
                {/* <img className={styles.img} src="/logo.png" /><span className={styles.logo_text}>Kraken<span style={{color: 'rgb(255,139,139)'}}>Pro</span></span> */}
            </h1>
            <div className={styles.nav__items}>
                <button onClick={()=>history.push('/sign-in')} style={{backgroundColor: 'transparent', color: 'white', fontWeight: 'bold', border: '2px solid white', padding: '.5rem', margin: '.3rem', borderRadius: '20px'}}>Sign In</button>
                <button onClick={()=>history.push('/sign-up')} style={{backgroundColor: 'white', color: 'blue', fontWeight: 'bold', border: '2px solid white', padding: '.5rem', borderRadius: '20px'}}>Create Account</button>
            </div>
        </div>
    )
}

export default Navbar
