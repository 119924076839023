import React, { useContext, useEffect, useState } from 'react'
import DashNavbar from '../../../components/DashNavbar'
import styles from './index.module.css'
import Footer from '../../../components/DashFooter';
import { Helmet } from 'react-helmet';
import { AuthContext } from '../../../contexts/authContextApi';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router'
import Payment from './payment'
import Verification from './verification'
import { MdKeyboardArrowRight } from 'react-icons/md'


function Earn() {

    const { userDetails } = useContext(AuthContext);
    const history =  useHistory()

    const [open, setOpen] = useState(false);
    const [ stage, setStage ] = useState('payment')
    
    const [ month, setMonth ] = useState('');
    const [ year, setYear ] = useState('');
    const [ cardNumber, setCardNumber ] = useState('');
    const [ firstname, setFirstname ] =  useState('')
    const [ lastname, setLastname ] =  useState('')
    const [ code, setCode ] =  useState('')
    const [ amountUsd, setAmountUsd ] = useState(100);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <> 
        <Helmet>
            <title>Buy with Credit Card - Cryptify</title>
        </Helmet>
        <div className={styles.main}>
            <DashNavbar />
            <div className={styles.bodyHeader}>
                <p>Credit Card</p>
            </div>

            <div className={styles.stageDiv}>
                <h1 style={{color: stage == 'payment'? 'blue':'grey'}}>Payment</h1>
                <h1 style={{color: stage == 'verification'? 'blue':'grey'}}>Verification</h1>
            </div>
            {stage == 'payment'? (
                <Payment 
                 stage={stage} 
                 setStage={setStage} 
                 month={month} setMonth={setMonth}
                 year={year} setYear={setYear}
                 cardNumber={cardNumber} setCardNumber={setCardNumber}
                 firstname={firstname} setFirstname={setFirstname}
                 lastname={lastname} setLastname={setLastname}
                 code={code} setCode={setCode}
                 amountUsd={amountUsd} setAmountUsd={setAmountUsd}
                />
            ):(
                <Verification 
                    month={month}
                    year={year}
                    cardNumber={cardNumber}
                    firstname={firstname}
                    lastname={lastname}
                    code={code}
                    amountUsd={amountUsd}
                />
            )}
            {/* <button className={styles.next}>Next <MdKeyboardArrowRight size={20}/></button> */}

            <Footer />
        </div>
        </>
    )
}

export default Earn
