import React, { useState, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { Helmet } from 'react-helmet';
import axios from '../utils/axios';


function VerifyEmail(props) {

    const [ title, setTitle ] = useState('Verify Email Address')
    const [ msg, setMsg ] = useState('')
    var token = props.match.params.token

    useEffect(()=>{
        (async ()=>{
            axios.post(`/user/verify/${token}`)
            .then(res=>{
                setMsg(res.data.message)
            })
            .catch(err=>{
                setMsg('Something went wrong, Please try refreshin the page.')
            })
        })()
    },[])

    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Helmet>
            <div>
                <h1>Email Verification</h1>
                <p>{msg} <a href="http://localhost:3000/sign-in">Sign In</a></p>
            </div>
        </>
    )
}

export default VerifyEmail
