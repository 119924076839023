import React, { useState, useContext } from "react";
import DashNavbar from "../../../components/DashNavbar";
import styles from "./index.module.css";
import Footer from "../../../components/DashFooter";
import { Helmet } from "react-helmet";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { CircularProgress } from "@material-ui/core";
import { AuthContext } from "../../../contexts/authContextApi";
import axios from "../../../utils/axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiFillDollarCircle, AiFillEuroCircle } from "react-icons/ai";
import { FaBitcoin, FaRegCreditCard } from "react-icons/fa";
import { SiEthereum } from "react-icons/si";
import { formatCurrency } from "../../../utils/formatCurrency";
import { useHistory } from "react-router";

function History() {
  const [open, setOpen] = useState(false);
  const [depositAmount, setDepositAmount] = useState(0);
  const [chosenWallet, setChosenWallet] = useState();
  const [errMsg, setErrMsg] = useState([false, ""]);
  const [verifying, setVerifying] = useState(false);

  const history = useHistory();
  const { userDetails, btcInfo, ethInfo, xrpInfo, algoInfo, solInfo } =
    useContext(AuthContext);
  let token = localStorage.getItem("krkn_pro");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const wallets = [
    {
      name: "bitcoin",
      qr: "/btc-qr.png",
      address: "bc1qmrltgy8a3599z0yk3k54vsqckjkat4fk4wvgqr",
    },
    {
      name: "ethereum",
      qr: "/ether-qr.png",
      address: "0x591Eba41517A775c1Cd564F157e1EB45150108BD",
    },
    {
      name: "xrp",
      qr: "/xrp-qr.png",
      address: "rNkjPybkzu9QqE4ULyHkqg5nrQFWTYBaGy",
    },
    {
      name: "algorand",
      qr: "/algorand-qr.png",
      address: "Z2FNJJAF624ADYWX54ONUTB2O2NTK4TAL5CXP72DA2IHALE5WVDLPJJQOI",
    },
    {
      name: "solana",
      qr: "/solana-qr.png",
      address: "5dEUy6SPeWnsk2gxLZt3itxbWrtjM8q9yXvoXHWG5jfa",
    },
  ];

  const getWallet = (walletName) => {
    var wallet;
    wallet = wallets.find((wallet) => wallet.name == walletName);
    setChosenWallet(wallet);
    console.log(wallet);
  };

  const verifyDeposit = async () => {
    if (depositAmount < 100) {
      var __ = () => {
        setErrMsg([true, "Amount deposited shouldn't be less than 100 USD"]);
        setTimeout(() => {
          setErrMsg([false, ""]);
        }, 4000);
      };
      return __();
    }
    setVerifying(true);
    axios
      .post(
        "/user/verify-deposit",
        {
          amount: depositAmount,
          wallet: chosenWallet.name,
          name: userDetails.firstname + " " + userDetails.lastname,
          email: userDetails.email,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": token,
          },
        }
      )
      .then((res) => {
        setVerifying(false);
        toast.info(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: false,
        });
        if (res.data.status) {
          setDepositAmount(0);
          handleClose();
        }
      })
      .catch((err) => {
        toast.error("Something went wrong, try again", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: false,
        });
        if (!err.response.data?.authenticated) {
          history.push("/sign-in");
        }
        setVerifying(false);
      });
  };

  const getFee = () => {
    var fee = 0;
    switch (userDetails.account) {
      case "Basic":
        fee = (userDetails.profit * 2) / 100;
        break;
      case "Intermediate":
        fee = (userDetails.profit * 5.5) / 100;
        break;
      case "Professional":
        fee = (userDetails.profit * 7.5) / 100;
        break;
    }
    return parseFloat(fee);
  };

  return (
    <>
      <ToastContainer />
      <Helmet>
        <title>Funding - Cryptify</title>
      </Helmet>
      <div className={styles.main}>
        <DashNavbar />
        <div className={styles.bodyHeader}>
          <p>Fund Wallet</p>
        </div>
        {/* <div className={styles.container2}>
                <div className={styles.container2__header}>
                    <h4 style={{color: 'grey', margin: 0}}>Choose Cryptocurrency</h4>
                </div>
                <div className={styles.container2__body}>
                    <div>
                      <img title='Bitcoin' onClick={()=>{handleClickOpen(); getWallet('bitcoin')}} src="/btc.png" width="30%" />
                      <img title="Ethereum" onClick={()=>{handleClickOpen(); getWallet('ethereum')}} src="/ether.png" width="30%" />
                    </div>
                </div>
                <p style={{textAlign: 'center', fontSize: '.85rem', color: 'grey'}}>Make sure each deposit is verified.</p>
            </div> */}

        <div className={styles.container3__1}>
          <div className={styles.container3__1_child}>
            <span
              style={{
                fontSize: ".7rem",
                justifyContent: "space-between",
                fontWeight: "bold",
              }}
            >
              TOTAL (USD){" "}
              <p style={{ fontSize: "1.3rem" }}>
                ${formatCurrency(userDetails.balance)}
              </p>
            </span>
            <span
              style={{ fontSize: ".7rem", justifyContent: "space-between" }}
            >
              Spot wallet <p>$0.00</p>
            </span>
            <span
              style={{ fontSize: ".7rem", justifyContent: "space-between" }}
            >
              Staked <p>$0.00</p>
            </span>
          </div>
          <div className={styles.container3__1_child}>
            <span
              style={{
                justifyContent: "flex-start",
                color: "grey",
                fontSize: ".7rem",
                fontWeight: "bold",
              }}
            >
              FUNDING LIMITS (USD) unlimited
            </span>
            <span
              style={{
                justifyContent: "space-between",
                color: "black",
                fontSize: ".7rem",
              }}
            >
              Daily Deposits <p>N/A</p>
            </span>
            <span
              style={{
                justifyContent: "space-between",
                color: "black",
                fontSize: ".7rem",
              }}
            >
              Daily Withdrawals <p>N/A</p>
            </span>
          </div>
          <div className={styles.container3__1_child}>
            <span
              style={{
                justifyContent: "space-between",
                color: "grey",
                fontSize: ".7rem",
                fontWeight: "bold",
              }}
            >
              REALIZED MARGINAL PROFIT{" "}
              <p>${formatCurrency(userDetails.profit - getFee())}</p>
            </span>
            <span
              style={{
                justifyContent: "space-between",
                color: "black",
                fontSize: ".7rem",
              }}
            >
              Account ROI <p>${formatCurrency(userDetails.profit)}</p>
            </span>
            <span
              style={{
                justifyContent: "space-between",
                color: "black",
                fontSize: ".7rem",
              }}
            >
              Fee Deducted <p>${formatCurrency(getFee())}</p>
            </span>
          </div>
        </div>

        <div className={styles.container3}>
          <div className={styles.container3_child}>
            <div className={styles.container3_child__body__1}>
              <br />
              <span style={{ fontSize: ".8rem", fontWeight: "bold" }}>
                CASH
              </span>
              <span style={{ fontSize: ".8rem" }}>
                <AiFillDollarCircle color="green" size={25} /> US Dollar
              </span>
              <span style={{ fontSize: ".8rem" }}>
                <AiFillEuroCircle color="blue" size={25} /> Euro
              </span>
              <br />
              <br />
              <span style={{ fontSize: ".8rem", fontWeight: "bold" }}>
                CRYPTO ASSETS
              </span>
              {/* <span style={{fontSize: '.8rem'}}><FaRegCreditCard size={23} color="blue" /> Credit Card <button className={styles.fund_btn} onClick={()=>{history.push('/u/fund/credit-card')}}>fund</button></span> */}
              <span style={{ fontSize: ".8rem" }}>
                <FaBitcoin size={25} color="orange" /> Bitcoin{" "}
                <button
                  className={styles.fund_btn}
                  onClick={() => {
                    handleClickOpen();
                    getWallet("bitcoin");
                  }}
                >
                  fund
                </button>
              </span>
              <span style={{ fontSize: ".8rem" }}>
                <SiEthereum size={25} color="blue" /> Ethereum{" "}
                <button
                  className={styles.fund_btn}
                  onClick={() => {
                    handleClickOpen();
                    getWallet("ethereum");
                  }}
                >
                  fund
                </button>
              </span>
              <span style={{ fontSize: ".8rem" }}>
                <img
                  src="/xrp.png"
                  style={{ borderRadius: "50%", width: "8%" }}
                />{" "}
                XRP{" "}
                <button
                  className={styles.fund_btn}
                  onClick={() => {
                    handleClickOpen();
                    getWallet("xrp");
                  }}
                >
                  fund
                </button>
              </span>
              <span style={{ fontSize: ".8rem" }}>
                <img
                  src="/algorand.png"
                  style={{ borderRadius: "50%", width: "8%" }}
                />{" "}
                Algorand{" "}
                <button
                  className={styles.fund_btn}
                  onClick={() => {
                    handleClickOpen();
                    getWallet("algorand");
                  }}
                >
                  fund
                </button>
              </span>
              <span style={{ fontSize: ".8rem" }}>
                <img
                  src="/solana.jpeg"
                  style={{ borderRadius: "50%", width: "8%" }}
                />{" "}
                Solana{" "}
                <button
                  className={styles.fund_btn}
                  onClick={() => {
                    handleClickOpen();
                    getWallet("solana");
                  }}
                >
                  fund
                </button>
              </span>
            </div>
          </div>
          <div className={styles.container3_child}>
            <div
              style={{ alignItems: "center" }}
              className={styles.container3_child__body}
            >
              <br />
              <span
                style={{
                  justifyContent: "flex-end",
                  color: "lightgrey",
                  fontSize: ".8rem",
                  fontWeight: "bold",
                }}
              >
                MARKET PRICE
              </span>
              <span
                style={{
                  justifyContent: "flex-end",
                  color: "black",
                  fontSize: ".8rem",
                }}
              >
                1.00 USD
              </span>
              <span
                style={{
                  justifyContent: "flex-end",
                  color: "black",
                  fontSize: ".8rem",
                }}
              >
                1.14 USD
              </span>
              <br />
              <br />
              <span
                style={{
                  justifyContent: "flex-end",
                  color: "lightgrey",
                  fontSize: ".8rem",
                  fontWeight: "bold",
                }}
              >
                MARKET PRICE
              </span>
              {/* <span style={{justifyContent: 'flex-end', color: 'black', fontSize: '.8rem'}}>--</span> */}
              <span
                style={{
                  justifyContent: "flex-end",
                  color: "black",
                  fontSize: ".8rem",
                }}
              >
                {formatCurrency(btcInfo?.buy)} USD
              </span>
              <span
                style={{
                  justifyContent: "flex-end",
                  color: "black",
                  fontSize: ".8rem",
                }}
              >
                {formatCurrency(ethInfo?.USD)} USD
              </span>
              <span
                style={{
                  justifyContent: "flex-end",
                  color: "black",
                  fontSize: ".8rem",
                }}
              >
                {formatCurrency(xrpInfo?.USD)} USD
              </span>
              <span
                style={{
                  justifyContent: "flex-end",
                  color: "black",
                  fontSize: ".8rem",
                }}
              >
                {formatCurrency(algoInfo?.USD)} USD
              </span>
              <span
                style={{
                  justifyContent: "flex-end",
                  color: "black",
                  fontSize: ".8rem",
                }}
              >
                {formatCurrency(solInfo?.USD)} USD
              </span>
            </div>
          </div>
          <div className={styles.container3_child}>
            <div className={styles.container3_child__body}>
              <br />
              <span
                style={{
                  justifyContent: "flex-end",
                  color: "lightgrey",
                  fontSize: ".8rem",
                  fontWeight: "bold",
                }}
              >
                AMOUNT
              </span>
              <span
                style={{
                  justifyContent: "flex-end",
                  color: "black",
                  fontSize: ".8rem",
                }}
              >
                {formatCurrency(userDetails?.balance)} USD
              </span>
              <span
                style={{
                  justifyContent: "flex-end",
                  color: "black",
                  fontSize: ".8rem",
                }}
              >
                {formatCurrency(userDetails?.balance)} USD
              </span>
              <br />
              <br />
              <span
                style={{
                  justifyContent: "flex-end",
                  color: "lightgrey",
                  fontSize: ".8rem",
                  fontWeight: "bold",
                }}
              >
                AMOUNT
              </span>
              {/* <span style={{justifyContent: 'flex-end', color: 'black', fontSize: '.8rem'}}>--</span> */}
              <span
                style={{
                  justifyContent: "flex-end",
                  color: "black",
                  fontSize: ".8rem",
                }}
              >
                {formatCurrency(userDetails?.balance)} USD
              </span>
              <span
                style={{
                  justifyContent: "flex-end",
                  color: "black",
                  fontSize: ".8rem",
                }}
              >
                {formatCurrency(userDetails?.balance)} USD
              </span>
              <span
                style={{
                  justifyContent: "flex-end",
                  color: "black",
                  fontSize: ".8rem",
                }}
              >
                {formatCurrency(userDetails?.balance)} USD
              </span>
              <span
                style={{
                  justifyContent: "flex-end",
                  color: "black",
                  fontSize: ".8rem",
                }}
              >
                {formatCurrency(userDetails?.balance)} USD
              </span>
              <span
                style={{
                  justifyContent: "flex-end",
                  color: "black",
                  fontSize: ".8rem",
                }}
              >
                {formatCurrency(userDetails?.balance)} USD
              </span>
            </div>
          </div>
          <div className={styles.container3_child}>
            <div className={styles.container3_child__body}>
              <br />
              <span
                style={{
                  justifyContent: "flex-end",
                  color: "lightgrey",
                  fontSize: ".8rem",
                  fontWeight: "bold",
                }}
              >
                VALUE
              </span>
              <span
                style={{
                  justifyContent: "flex-end",
                  color: "black",
                  fontSize: ".8rem",
                }}
              >
                {formatCurrency(userDetails?.balance)} USD
              </span>
              <span
                style={{
                  justifyContent: "flex-end",
                  color: "black",
                  fontSize: ".8rem",
                }}
              >
                {formatCurrency(userDetails?.balance / 1.14)} EUR
              </span>
              <br />
              <br />
              <span
                style={{
                  justifyContent: "flex-end",
                  color: "grey",
                  fontSize: ".8rem",
                  fontWeight: "bold",
                }}
              >
                VALUE
              </span>
              {/* <span style={{justifyContent: 'flex-end', color: 'black', fontSize: '.8rem'}}>--</span> */}
              <span
                style={{
                  justifyContent: "flex-end",
                  color: "black",
                  fontSize: ".8rem",
                }}
              >
                {(userDetails.balance / btcInfo?.buy).toFixed(5)} BTC
              </span>
              <span
                style={{
                  justifyContent: "flex-end",
                  color: "black",
                  fontSize: ".8rem",
                }}
              >
                {(userDetails.balance / ethInfo?.USD).toFixed(5)} ETH
              </span>
              <span
                style={{
                  justifyContent: "flex-end",
                  color: "black",
                  fontSize: ".8rem",
                }}
              >
                {(userDetails.balance / xrpInfo?.USD).toFixed(5)} XRP
              </span>
              <span
                style={{
                  justifyContent: "flex-end",
                  color: "black",
                  fontSize: ".8rem",
                }}
              >
                {(userDetails.balance / algoInfo?.USD).toFixed(5)} ALGO
              </span>
              <span
                style={{
                  justifyContent: "flex-end",
                  color: "black",
                  fontSize: ".8rem",
                }}
              >
                {(userDetails.balance / solInfo?.USD).toFixed(5)} SOL
              </span>
            </div>
          </div>
        </div>

        <Dialog open={open} aria-labelledby="form-dialog-title">
          {/* <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title"> */}
          {chosenWallet && (
            <>
              <h2 style={{ textAlign: "center" }}>
                Fund Account with{" "}
                <span style={{ textTransform: "capitalize" }}>
                  {chosenWallet.name}
                </span>
                <br />
              </h2>
              <p
                style={{
                  color: "grey",
                  margin: 0,
                  fontStyle: "italic",
                  fontSize: ".65rem",
                  textAlign: "center",
                }}
              >
                Send only{" "}
                <span
                  style={{ textTransform: "capitalize", fontWeight: "bold" }}
                >
                  {chosenWallet.name}
                </span>{" "}
                to this address.
                <br /> Sending any other Cryptocurrency may result in permanent
                loss.
              </p>

              <div
                style={{
                  display: "flex",
                  padding: "1rem",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img src={chosenWallet.qr} width="58%" />
                <span
                  style={{
                    fontSize: ".8rem",
                    border: ".5px solid lightgrey",
                    padding: ".2rem",
                    borderRadius: "7px",
                  }}
                >
                  {chosenWallet.address}
                </span>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Amount in USD (min. 100 USD)"
                  type="number"
                  value={depositAmount}
                  onChange={(e) => setDepositAmount(e.target.value)}
                  placeholder="100"
                  fullWidth
                />
                {errMsg[0] && (
                  <span style={{ color: "orange", fontSize: ".8rem" }}>
                    {errMsg[1]}
                  </span>
                )}
              </div>
              <p
                style={{
                  color: "grey",
                  fontSize: ".7rem",
                  textAlign: "center",
                }}
              >
                After making a deposit, click <b>verify deposit</b> to proceed.
              </p>
              <p
                style={{
                  color: "grey",
                  margin: 0,
                  fontSize: ".7rem",
                  textAlign: "center",
                }}
              >
                You will be notified of your deposit after confirmation, <br />
                be sure to check your spam folder if you do not receive an email
              </p>
              <p
                style={{
                  color: "grey",
                  margin: 0,
                  fontSize: ".7rem",
                  textAlign: "center",
                }}
              >
                <b>Note</b>: Confirmation might take a while.
              </p>
              <DialogActions>
                {!verifying && (
                  <Button onClick={handleClose} color="primary">
                    Close
                  </Button>
                )}

                {!verifying ? (
                  <Button onClick={() => verifyDeposit()} color="primary">
                    verify deposit
                  </Button>
                ) : (
                  <CircularProgress size={20} />
                )}
              </DialogActions>
            </>
          )}
        </Dialog>
        <br />
        <br />
        <br />
        <Footer />
      </div>
    </>
  );
}

export default History;
