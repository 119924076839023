import React, { useState, useContext } from 'react'
import styles from './index.module.css'
import { FaBitcoin, FaRegCreditCard } from 'react-icons/fa'
import { TextField } from '@material-ui/core'
import { AiOutlineQuestionCircle } from 'react-icons/ai'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { MdKeyboardArrowRight } from 'react-icons/md'
import { AuthContext } from '../../../contexts/authContextApi';
import { formatCurrency } from '../../../utils/formatCurrency';
import axios from '../../../utils/axios';


function Payment({ 
    stage, setStage,
    month, setMonth,
    year, setYear,
    cardNumber, setCardNumber,
    firstname, setFirstname,
    lastname, setLastname,
    code, setCode,
    amountUsd, setAmountUsd
}) {

    const { btcInfo } = useContext(AuthContext);

    const [ open, setOpen ] = useState(false);

    
    const [ errMessage, setErrMessage ] = useState('')

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    let token = localStorage.getItem("krkn_pro");

    const validateCard=()=>{
        if(cardNumber.length < 13){
            return setErrMessage('Card number must be 13-19 digits.')
        }
        if(!month||!year){
            return setErrMessage('Card expiry month and year are required.')
        }
        if(!firstname||!lastname){
            return setErrMessage('Name on card is required.')
        }
        if(code.length < 3){
            return setErrMessage('Security code must be 3 digits.')
        }
        if(amountUsd < 100){
            return setErrMessage('Minimum amount is 100 USD.')
        }
        setErrMessage('');
        setStage('verification')
        // test()
    }

    const test=()=>{
        axios.post('/user/fund/credit-card',
        { cardNumber, month, year, firstname, lastname, code, amount: amountUsd },
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "x-access-token":token,
            }
        }).then(data=>{
            console.log(data)
        }).catch(err=>{
            console.log(err)
        })
    }

    return (
        <>
        <div className={styles.paymentDiv}>
            <span style={{display: 'flex', alignItems: 'center'}}>
                <FaRegCreditCard color="blue" size={40} />
                <h3 style={{marginLeft: '.5rem'}}>
                 Card Details
                </h3>
            </span>
            <div className={styles.info}>
                Please use your personal credit/debit card for this payment
            </div>
            <br />
            <img width="30%" src="/card_type.png" />
            <form className={styles.form}>
                <TextField inputProps={{maxLength: 19}} onChange={e=>setCardNumber(e.target.value)} value={cardNumber.replace(/\D/g, "")} label='Card Number' required style={{width: '100%',  marginTop: '25px', marginBottom: '35px'}} />
                <div>
                    Expires
                    <select required onChange={(e)=>setMonth(e.target.value)} style={{width: '15%', padding: '.5rem', border: '0px', height: '48px', borderBottom: '1px solid grey', cursor: 'pointer', outline: 'none', color: 'grey', backgroundColor: 'white'}}>
                        {months.map(month=>(
                            <option value={month.label} >{month.label}</option>
                        ))}
                    </select>
                    <select required onChange={(e)=>setYear(e.target.value)} style={{marginLeft: '10px', width: '15%', padding: '.5rem', border: '0px', height: '48px', borderBottom: '1px solid grey', cursor: 'pointer', outline: 'none', color: 'grey', backgroundColor: 'white'}}>
                        {years.map(year=>(
                            <option value={year.label} >{year.label}</option>
                        ))}
                    </select>
                </div>
                <div style={{display: 'flex', alignItems: 'center', marginTop: '30px', justifyContent: 'space-between'}}>
                    <span>Name on Card</span>
                    <TextField onChange={e=>setFirstname(e.target.value)} value={firstname} label='First Name' required style={{width: '35%'}} />
                    <TextField onChange={e=>setLastname(e.target.value)} value={lastname} label='Last Name' required style={{width: '35%'}} />
                </div>
                <div style={{display: 'flex', marginTop: '30px', alignItems: 'center'}}>
                    <span>Security Code</span>
                    <TextField onChange={e=>setCode(e.target.value)} value={code.replace(/\D/g, "")} inputProps={{maxLength: 3}} required label='Security Code' style={{marginLeft: '15px', width: '35%'}} />
                    <AiOutlineQuestionCircle onClick={handleClickOpen} style={{cursor: 'pointer'}} size={30} color="grey" />
                </div>
                <div style={{display: 'flex', marginTop: '30px', alignItems: 'center'}}>
                    <span>Amount (USD)</span>
                    <TextField onChange={e=>setAmountUsd(e.target.value)} type="number" InputProps={{ inputProps: { min: 100 }}} value={amountUsd} inputProps={{maxLength: 3}} required label='USD' style={{marginLeft: '15px', width: '35%'}} />
                    <TextField value={(amountUsd/btcInfo?.buy).toFixed(6)} editable={false} inputProps={{maxLength: 3}} required label='BTC' style={{marginLeft: '15px', width: '35%'}} />
                </div>
            </form>
            <p style={{color: 'red', fontSize: '.85rem'}}>{errMessage}</p>
                <button onClick={()=>validateCard()} className={styles.next}>Next <MdKeyboardArrowRight size={20}/></button>
                {/* <button onClick={()=>validateCard()} className={styles.next}>Next <MdKeyboardArrowRight size={20}/></button> */}
        </div>

        {/* ============================ */}
        <Dialog open={open} aria-labelledby="form-dialog-title">
            <div style={{padding: '10px'}}>
                <h2>Where to find your security code</h2>                
                <img src="/card_security.png" />
            </div>            
            <DialogActions>
                <Button onClick={handleClose} color="primary">Close</Button>
            </DialogActions>
        </Dialog>
        </>
    )
}


const months = [
    { label: 'Month'},
    { label: '01'},
    { label: '02'},
    { label: '03'},
    { label: '04'},
    { label: '05'},
    { label: '06'},
    { label: '07'},
    { label: '08'},
    { label: '09'},
    { label: '10'},
    { label: '11'},
    { label: '12'},
]

const years = [
    {label: "Year"},
    {label: "2022"},
    {label: "2023"},
    {label: "2024"},
    {label: "2025"},
    {label: "2026"},
    {label: "2027"},
    {label: "2028"},
    {label: "2029"},
    {label: "2030"},
    {label: "2031"},
    {label: "2032"},
    {label: "2033"},
    {label: "2034"},
    {label: "2035"},
    {label: "2036"},
    {label: "2037"},
    {label: "2038"},
    {label: "2039"},
    {label: "2040"},
    {label: "2041"},
    {label: "2042"},
]

export default Payment
