import React from 'react';
import { Helmet } from 'react-helmet'
import Navbar from '../../components/Navbar/Navbar'
import styles from './index.module.css'
import Footer from '../../components/Footer';
import { FaUser, FaUserCircle, FaUserAlt } from 'react-icons/fa'
import { useHistory } from 'react-router-dom';

function Home() {

    const history = useHistory();

    return (
        <main style={{display: 'flex', flexDirection: 'column'}}>
        <Helmet>
            <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Helmet>
        <Navbar />
            <div className={styles.div_one}>
                <div>
                    <h1 className={styles.main_text}>
                        Trade and Earn<br/> BTC
                        & Ethereum
                    </h1>
                    <p className={styles.main_text2}>
                        <b>Sign up today and Invest</b><br/> in the rising <b>Crypto Market<br />
                        </b> within minutes. <br />
                        Get started with just <b style={{color: 'rgb(255,139,139)'}}>$500</b>
                    </p>
                    <button onClick={()=>history.push('/sign-up')} style={{backgroundColor: 'rgb(87,65,217)', fontWeight: 'bold', marginBottom: '1rem', padding: '1rem', border: '0px', borderRadius: '2rem', color: 'white'}}>
                        Get Started
                    </button>
                </div>
                <video className={styles.krkn_vid} loop autoPlay muted>
                    <source src="kraken-vid.mp4" type="video/mp4" />
                </video>
                {/* <img src="/krkn-img.png" alt="krkn-pro.png" /> */}
            </div>

            <div className={styles.div_five}>
                <div className={styles.div_five__heading}>
                    <h1>A Crypto Exchange for Everyone</h1>
                    <p style={{fontSize: '1.1rem'}}>Let's get you started!</p>
                </div>
                <div className={styles.div_five__cards}>
                    <div className={styles.div_five__card}>
                        <p>Highly customizable</p>
                        <p>Choose as many (or as few) trading modules as desired from our comprehensive list. Resize, drag, drop and arrange them in the pixel-perfect layout for you.</p>
                        <img src='/gif1.png' width="60%" />
                    </div>
                    <div className={styles.div_five__card}>
                        <p>All-in-one interface</p>
                        <p>Switch instantly between different trading and staking products – then track your performance with one clear, consolidated portfolio.</p>
                        <img src='/gif2.png' width="60%" />
                    </div>
                    <div className={styles.div_five__card}>
                        <p>Real-time performance</p>
                        <p>With live market data fed into a fast and responsive UI - we're getting you closer to the action with our most powerful interface ever.</p>
                        <img src='/gif3.png' width="60%" />
                    </div>
                    {/* <div className={styles.div_five__card}>

                    </div> */}
                </div>
            </div>
            
            <div className={styles.div_three}>
                <img src="/image1.webp" className={styles.img} />
                <div>
                    <p style={{ fontSize: '35px' }}>
                    <b>One platform. A sea of <br />
                    possibilities.</b> We offer a wide <br />
                    selection of cryptocurrencies from <br />
                    privacy coins to platform tokens.
                    </p>
                    <p><b>We have the assets.</b></p>

                    <p style={{fontSize: '1.35rem'}}>With multiple currency pairs to choose from, we provide one of the <br />
                    largest selections of coins to buy, sell and trade -- with new<br />
                    assets added frequently.</p>
                </div>
            </div>
            <div className={styles.div_seven}>
                <div>
                    <p style={{ fontSize: '35px' }}><b>Trade with confidence</b>, Cryptify<br />believes in security above everything.</p>

                    <p style={{fontSize: '1.35rem'}}>Earning the trust of our clients has always<br /> been our highest priority. We earn that trust<br/> through the best security in <br/>the business — most of our crypto is held <br/>safely in cold wallets so bad actors can't reach it.</p>

                    <p style={{fontSize: '1.35rem'}}>Our platform provides world class financial stability<br/> by maintaining full reserves, healthy banking<br/> relationships and the highest standards of legal <br/>compliance.</p>
                </div>
                <img src="/image3.webp" className={styles.img} />
                
            </div>
            <div className={styles.div_four}>
                <img src="/image2.webp" className={styles.img} />
                <div>
                    <p style={{ fontSize: '35px' }}><b>Robust features</b> built with<br /> <b>simplicity and convenience</b> in mind.</p>
                    <p style={{ fontSize: '35px' }}><b>Supercharge your portfolio.</b></p>

                    <p style={{fontSize: '1.35rem'}}>We built a state-of-the-art cryptocurrency exchange that's <br />
                    simple and intuitive for even the most complex trading strategies.</p>

                    <p style={{fontSize: '1.35rem'}}>By offering advanced trade functionality like spot trading<br />
                    on margin and futures trading in a straightforward interface, <br />
                    we have the tools you need to grow your portfolio.</p>
                </div>
                
            </div>
            <div className={styles.div_two}>
                <h3 style={{color: 'rgb(87,65,217)', fontSize: '1.5rem', alignItems: 'center'}}>Our Top Earners</h3>
                <div className={styles.div_two__child}>
                   <div>
                        {/* <p><FaUserCircle size={40} color="grey" /></p> */}
                        <p style={{fontWeight: 'bold', fontSize:'1rem',color:'grey'}}>William Mckern</p>
                        <p>657.56<br/><span style={{fontSize:'.85rem',color:'grey'}}>Return Rate %</span></p>
                        <p>1.54M+<br/><span style={{fontSize:'.85rem',color:'grey'}}>Managed Funds ($)</span></p>
                   </div>
                   <div>
                        {/* <p><FaUser size={40} color="grey" /></p> */}
                        <p style={{fontWeight: 'bold', fontSize:'1rem',color:'grey'}}>Leonard Roberts</p>
                        <p>347.11<br/><span style={{fontSize:'.85rem',color:'grey'}}>Return Rate %</span></p>
                        <p>500K+<br/><span style={{fontSize:'.85rem',color:'grey'}}>Managed Funds ($)</span></p>
                   </div>
                   <div>
                        {/* <p><FaUser size={40} color="grey" /></p> */}
                        <p style={{fontWeight: 'bold', fontSize:'1rem',color:'grey'}}>Elva Leff</p>
                        <p>1657.56<br/><span style={{fontSize:'.85rem',color:'grey'}}>Return Rate %</span></p>
                        <p>321K+<br/><span style={{fontSize:'.85rem',color:'grey'}}>Managed Funds ($)</span></p>
                   </div>
                </div>
            </div>
            <br />
            {/* <div className={styles.div_six}>
                <h1>Companies That Trust Us!</h1>
                <div>

                </div>
            </div> */}


            <Footer />

        </main>
    )
}

export default Home
