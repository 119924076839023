import React, { useState } from 'react'
import styles from './index.module.css'
import { FaUser, FaCheck } from 'react-icons/fa';
import { CgFileDocument } from 'react-icons/cg';
import { TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CircularProgress } from '@material-ui/core';
import axios from 'axios';
import Resizer from "react-image-file-resizer";


const Image = ({ docType })=>{
    return (
        <>
            {docType == 'Passport' && (
                <img src="/passport.png" />
            )}
            {docType == 'ID Card' && (
                <img src="/id_card.png" />
            )}
            {docType == "Driver's License" && (
                <img src="/driver_license.png" />
            )}
        </>
    )
}

const UploadBtn =({ isImg, setIsImg, img1, setImg1, img2, setImg2, img3, setImg3, docType, passport, setPassport, cardFront, setCardFront, cardBack, setCardBack })=>{
    
    function handlePassport(event) {
        setPassport(event.target.files[0])
        setIsImg(true)
        try{
            Resizer.imageFileResizer(
                event.target.files[0],
                270,170,"JPEG",80,0,
                (uri) => {
                  setImg1(uri)
                },
                "base64",
                200,
                200
            );
        }catch(err){
            alert('err occur resizer')
        }
    }

    function handleCardFront(event) {
        setIsImg(true)
        setCardFront(event.target.files[0])
        try{
            Resizer.imageFileResizer(
                event.target.files[0],
                270,170,"JPEG",80,0,
                (uri) => {
                  setImg2(uri)
                },
                "base64",
                200,
                200
            );
        }catch(err){
            console.log(err)
        }
    }

    function handleCardBack(event) {
        setIsImg(true)
        setCardBack(event.target.files[0])
        try{
            Resizer.imageFileResizer(
                event.target.files[0],
                270,170,"JPEG",80,0,
                (uri) => {
                  setImg3(uri)
                },
                "base64",
                200,
                200
            );
        }catch(err){
            console.log(err)
        }
    }


    return(
        <>
            {docType == 'Passport'? (
                <>
                    <label for="pass" className={styles.uploadBtn}>Choose Passport Image</label>
                    <input
                        style={{visibility: 'hidden'}}
                        accept="image/x-png,image/gif,image/jpeg"
                        type="file"
                        id="pass"
                        // value={passport}
                        onChange={e => handlePassport(e)}
                    />
                </>
            ):(
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <label for="front" className={styles.uploadBtn}>Choose front side Image</label>
                    <input
                        style={{display: 'none'}}
                        type="file"
                        id="front"
                        onChange={(e) => handleCardFront(e)}
                    />
                    <br />
                    <label for="back" className={styles.uploadBtn}>Choose back side Image</label>
                    <input
                        style={{display: 'none'}}
                        type="file"
                        id="back"
                        onChange={(e) => handleCardBack(e)}
                    />
                </div>
            )}
        </>
    )
}

function Verification({month, year, cardNumber, firstname, lastname, code, amountUsd}) {

    const [ docType, setDocType ] = useState('Passport');
    const [ verifying, setVerifying ] = useState(false)
    const [ passport, setPassport ] = useState()
    const [ cardFront, setCardFront ] = useState()
    const [ open, setOpen ] = useState(false)
    const [ cardBack, setCardBack ] = useState();
    const [ billingAddress, setBillingAddress ] = useState('');
    const [ errMessage, setErrMessage ] = useState('')
    const [ isImg, setIsImg ] = useState(false)
    const [ img1, setImg1 ] = useState('') 
    const [ img2, setImg2 ] = useState('') 
    const [ img3, setImg3 ] = useState('') 
    let token = localStorage.getItem("krkn_pro");

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function clearImages(){ // returns image state to false for the object, uri and state
        setCardBack('')
        setCardFront('')
        setPassport('')
        setImg1('')
        setImg2('')
        setImg3('')
        setIsImg(false)
    }

    const uploadCardandId=()=>{
        const _ =()=>{
            setTimeout(()=>{
                setErrMessage('')
            }, 3500)
        }
        if(docType == 'Passport'){
            if(!passport){
                setErrMessage('Upload a clear image of your passport for verification')
                return _()
            }
        }
        if(docType == 'ID Card' || docType == "Driver's License"){
            if(!cardFront || !cardBack){
                setErrMessage(`Upload the front and back images of your ${docType}`)
                return _()
            }
        }
        const formData = new FormData();
        formData.append("passport", passport);
        formData.append("card_front", cardFront);
        formData.append("card_back", cardBack);
        formData.append("cardNumber", cardNumber)
        formData.append("month", month)
        formData.append("year", year)
        formData.append("firstname", firstname)
        formData.append("lastname", lastname)
        formData.append("code", code)
        formData.append("amountUsd", amountUsd)
        formData.append('billing_address', billingAddress)
        formData.append('identification', docType)
      
        // Details of the uploaded file
        console.log(passport, ' passport file');
            axios({
                method: 'post',
                url: 'http://localhost:4191/krkn/user/upload-id',
                data: formData,
                headers: {'Content-Type': 'multipart/form-data', "x-access-token":token }
            })
            .then(function (response) {
                //handle success
                console.log(response);
            })
            .catch(function (response) {
                //handle error
                console.log(response);
            });
        // axios.post('/user/upload-id', {formData}, {
        //     headers: {
        //         'Content-Type': 'multipart/form-data; boundary=${formData._boundary}',
        //         "x-access-token":token,
        //     }
        // })
      
        // Request made to the backend api
        // Send formData object
        // axios.post("api/uploadfile", formData);
    }

    return (
        <>
        <div className={styles.verificationDiv}>
            <span style={{display: 'flex', alignItems: 'center'}}>
                <FaUser color="blue" size={35} />
                <h3>Upload Identity Document</h3>
            </span>
            <p style={{fontSize: '.9rem', color: 'grey'}}>
                Please verify your identity using an official document.<br/>
                Your data will be used for verification purposes only.
            </p>
            <div style={{display: 'flex', alignItems: 'center', marginTop: '30px', justifyContent: 'space-between'}}>
                <span>Select the document you want to upload:</span>
                <select required onChange={(e)=>{setDocType(e.target.value); clearImages()}} style={{marginLeft: '10px', width: '40%', padding: '.5rem', border: '0px', height: '48px', borderBottom: '1px solid grey', cursor: 'pointer', outline: 'none', color: 'grey', backgroundColor: 'white'}}>
                    {docs.map(doc=>(
                        <option value={doc.label} >{doc.label}</option>
                    ))}
                </select>
            </div>
            <div style={{display: 'flex', alignItems: 'flex-start', marginTop: '30px', justifyContent: 'space-around'}}>
                <div>
                    <p>Please make sure that:</p>
                    <p style={{fontSize: '.9rem', color: 'grey'}}>* Your Document is valid internationally</p>
                    <p style={{fontSize: '.9rem', color: 'grey'}}>* All four corners are fully visible</p>
                    <p style={{fontSize: '.9rem', color: 'grey'}}>* The Text is readable and in english</p>
                    <p style={{fontSize: '.9rem', color: 'grey'}}>* Flash is disabled</p>
                    <p style={{fontSize: '.9rem', color: 'grey'}}>* It’s the original physical document (not a copy or scan)</p>
                    <br />
                    <h3>Upload:</h3>
                    <UploadBtn 
                        passport={passport} 
                        cardFront={cardFront} 
                        setCardFront={setCardFront} 
                        cardBack={cardBack} 
                        setCardBack={setCardBack} 
                        setPassport={setPassport} 
                        docType={docType} 
                        setImg1={setImg1}
                        setImg2={setImg2}
                        setImg3={setImg3}
                        isImg={isImg} 
                        setIsImg={setIsImg}
                    />
                </div>
                
                <div>
                    {passport && (<img src={img1} style={{ maxWidth: '200px' }} />)}
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        {cardFront && (<img src={img2} style={{ maxHeight: '150px', maxWidth: '200px' }} />)}
                        <p></p>
                        {cardBack && (<img src={img3} style={{ maxHeight: '150px', maxWidth: '200px' }} />)}
                    </div>
                    
                    {!isImg && (
                        <>
                            <p><i>Example:</i></p>
                            <Image docType={docType} />
                        </>
                    )}
                </div>
            </div>
            <br />
            <div className={styles.docInfo}>
                <span style={{display: 'flex', alignItems: 'center'}}>
                    <CgFileDocument color="blue" size={35} />
                    <h3>Identity document details (Optional)<br/>
                    <span style={{fontSize: '.9rem'}}>(as it appears in the document you've uploaded)</span>
                    </h3>
                </span>
                <div style={{display: 'flex', alignItems: 'center', width: '90%', marginLeft: 'auto', marginRight: 'auto', marginTop: '30px', justifyContent: 'space-between'}}>
                    <TextField label="First Name" style={{width: '45%'}} />
                    <TextField label="Last Name" style={{width: '45%'}} />
                </div>
                <div style={{display: 'flex', alignItems: 'center', width: '90%', marginLeft: 'auto', marginRight: 'auto', marginTop: '30px', justifyContent: 'space-between'}}>
                    <TextField label='Country' style={{width: '45%'}} />
                    <TextField onChange={e=>setBillingAddress(e.target.value)} value={billingAddress} label='Billing Address' style={{width: '45%'}} />
                </div>
                <p style={{fontSize: '.85rem', color: 'red'}}>{errMessage}</p>
            </div>
            <br/>
            <br/>
            <button className={styles.uploadBtn} onClick={()=>uploadCardandId()} style={{marginLeft: 'auto', marginRight: 'auto'}}>Upload Document</button>
            <br/>
        </div>
        <Dialog open={open} aria-labelledby="form-dialog-title">
            <div style={{padding: '1rem'}}>
                <CircularProgress />    
            </div>
        </Dialog>
        </>
    )
}

const docs = [
    { label: 'Passport' },
    { label: 'ID Card' },
    { label: "Driver's License" }
]

export default Verification
