import Home from './pages/Home'
import Signup from './pages/Signup'
import Signin from './pages/Signin'
import Trade from './pages/Dashboard/Trade'
import Earn from './pages/Dashboard/Earn'
import History from './pages/Dashboard/History'
import Funding from './pages/Dashboard/Funding'
import Card from './pages/Dashboard/Cards'
import Account from './pages/Dashboard/Account'
import VerifyEmail from './pages/VerifyEmail';
import ForgotPassword from './pages/ForgotPassword';
import Admin from './pages/Admin'
import { Route, Switch } from 'react-router-dom';
import NotFound from './pages/404'
import ProtectedRoute from './components/ProtectedRoute'

function App() {
  return (
     <Switch>
          <Route path='/' component={Home} exact />
          <Route path='/sign-up' component={Signup} exact />
          <Route path='/sign-in' component={Signin} exact />
          <Route path='/verify/:token' component={VerifyEmail} exact />
          <Route path='/reset-password/:token' component={ForgotPassword} exact />
          <Route path='/kraken-pro/author' component={Admin} exact />
          
          {/* <ProtectedRoute path='/home' component={DashHome} exact /> */}
          <ProtectedRoute path='/u/trade' component={Trade} exact />
          <ProtectedRoute path='/u/earn' component={Earn} exact />
          <ProtectedRoute path='/u/history' component={History} exact />
          <ProtectedRoute path='/u/funding' component={Funding} exact />
          <ProtectedRoute path='/u/fund/credit-card' component={Card} exact />
          <ProtectedRoute path='/u/account' component={Account} exact />
          <Route path="*" component={NotFound} />
      
      </Switch>
  );
}

export default App;
